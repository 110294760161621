import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { csvMasterUploadsDownloadsConfig } from './csv-master-uploads-downloads.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { Subscription } from 'rxjs';
import { CsvMasterUploadsDownloadsService } from 'src/app/services/csv-master-uploads-downloads.service';

@Component({
  selector: 'app-csv-master-uploads-downloads',
  templateUrl: './csv-master-uploads-downloads.component.html',
  styleUrls: ['./csv-master-uploads-downloads.component.scss'],
})
export class CsvMasterUploadsDownloadsComponent implements OnInit {
  
  @Input() input: csvMasterUploadsDownloadsConfig;
  @Output() onUploadCompleted = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileName') fileName: ElementRef;
  uploadSub: Subscription = new Subscription();

  constructor(
    private ds: DataService,
    private weds: WaitErrorDialogsService,
    private csvMasterUploadsDownloadsUtil: CsvMasterUploadsDownloadsService
  ) {}

  ngOnInit(): void {}

  generateTemplate() {
    const CSVFileDownloadHeader = this.csvMasterUploadsDownloadsUtil.getTemplateHeaderKeysMapper(
      this.input.entityName,
      this.input.entityAlias
    );
    const downloadTemplateCSVString = this.csvMasterUploadsDownloadsUtil.getDownloadTemplateString(
      CSVFileDownloadHeader
    );
    this.csvMasterUploadsDownloadsUtil.downloadCSVMasterFile(
      'data:text/csv;base64,' + window.btoa(downloadTemplateCSVString),
      `${this.input.entityAlias} upload Template.csv`
    );
  }

  onFileClick(_) {
    this.fileInput.nativeElement.click();
  }
  onFileSelect(_) {
    let file = this.fileInput.nativeElement.files[0];
    if (file) this.fileName.nativeElement.innerHTML = file.name;
  }

  async onUpload() {
    const file = this.fileInput.nativeElement.files[0];
    if (!file) {
      this.weds.showDialog({
        type: 'generic',
        title: 'Select File',
        message: 'Please select a .csv file to upload',
        btnTitle: 'OK',
      });
      return;
    }
    let dialogData = {
      type: 'wait',
      title: 'Uploading',
      message: 'Please wait while the file is uploaded',
      showProgress: true,
      progress: 0,
    };
    this.weds.showDialog(dialogData);
    const saveUploadFileResponse = await this.ds
      .uploadFiles(
        this.fileInput.nativeElement.files,
        this.ds.currentAdminClientId,
        ['application/csv'],
        500
      )
      .toPromise();

    const saveUploadFileStatusCode = saveUploadFileResponse['status'];
    const { type, status: saveUploadFileStatus } = saveUploadFileResponse['body'];

    if (type == 1) {
      const { loaded, total } = saveUploadFileResponse['body'];
      dialogData.progress = Math.round((loaded * 100) / total);
    } else if (saveUploadFileStatusCode == 200) {
      this.weds.closeDialog();

      if (saveUploadFileStatus == 'Success') {
        this.weds.showDialog({ type: 'wait', code: -5 });

        const { files } = saveUploadFileResponse['body'];
        const saveIntegrationRequestData = {
          integrationType: this.input.integrationType,
          type: 'Upload File',
          clientId: this.input.clientId,
          file: files[0],
          ...this.input.extraPayload,
        };
        
        const saveIntegrationsResponse = await this.ds
          .saveData('admin/saveIntegrations', saveIntegrationRequestData)
          .toPromise();
        const saveIntegrtionsStatus = saveIntegrationsResponse['status'];
        this.weds.closeDialog();

        if (saveIntegrtionsStatus == 'Success') {
          this.weds.showDialog({ type: 'generic', code: -1 });
          setTimeout(() => {
            this.onUploadCompleted.emit();
          }, 5000);
        } else {
          const { error } = saveIntegrationsResponse['body'];
          this.weds.showDialog({
            type: 'generic',
            code: error ? error.code : 99,
          });
        }
      } else {
        const { error } = saveUploadFileResponse['body'];
        this.weds.showDialog({
          type: 'generic',
          code: error ? error.code : 99,
        });
      }
    } else if (!type && type !== 0 && saveUploadFileStatusCode != 200) {
      this.weds.closeDialog();
      this.weds.showDialog({ type: 'generic', code: 0 });
    }
  }
}
