import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-skill-rating-filter-dropdown-dialog',
  templateUrl: './skill-rating-filter-dropdown-dialog.component.html',
  styleUrls: ['./skill-rating-filter-dropdown-dialog.component.scss']
})
export class SkillRatingFilterDropdownDialogComponent implements OnInit {
  @Output() onEvent = new EventEmitter();
  maxRating = 5;
  ratingStep = 0.5;
  ratingOptions = [];
  ratingAlias: string = '';
  @Input() currentMinRating = 0;
  @Input() currentMaxRating = 5;
  @Input() title = "Skill Rating";
  constructor(private ds: DataService) { }

  ngOnInit(): void {
    let client = this.ds.client;
    if (client.smsSettings.skillRating == 'Yes/No') { this.maxRating = 1; this.ratingStep = 0.1; }
    if (client.smsSettings.skillRating == 'Numeric: 1-10') { this.maxRating = 10; this.ratingStep = 1; }
    if (client.smsSettings.skillRating == 'Stars 1-4') { this.maxRating = 4; }
    this.ratingAlias = this.ds.dictionary.rating.toLowerCase();
    let total = 0; while (total <= this.maxRating) {
      this.ratingOptions.push(total); total += this.ratingStep * 2;
    }
  }
  maxClick = false;
  onRatingClick(category, n) {
    if ((n && n == this.currentMinRating) || (n < this.maxRating && n == this.currentMaxRating)) return;
    if (n >= this.currentMaxRating) this.maxClick = true;
    if (n <= this.currentMinRating) this.maxClick = false;
    if (this.maxClick) this.currentMaxRating = n;
    else this.currentMinRating = n;
    this.maxClick = !this.maxClick;
  }
  onApply() {
    this.onEvent.emit({ type: 'Skill Rating', currentMinRating: this.currentMinRating, currentMaxRating: this.currentMaxRating });
  }

}
