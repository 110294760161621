import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataOrderComponentConfig } from './data-ordering-control.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-data-ordering-control',
  templateUrl: './data-ordering-control.component.html',
  styleUrls: ['./data-ordering-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DataOrderingControlComponent,
    },
  ],
})
export class DataOrderingControlComponent
  implements OnInit, ControlValueAccessor
{
  @Input() input: DataOrderComponentConfig;
  @Output() onSave: EventEmitter<any> = new EventEmitter();

  onChange = (selectedItems: Array<any>) => {};
  onTouched = () => {};
  disabled = false;
  touched = false;

  listItemsFormGroup = new FormGroup({});
  selectedItems = [];

  constructor() {}

  ngOnInit(): void {
    if (this.input.values.length > 0) {
      this.selectedItems.push(...this.input.values);
    }
    this.listItemsFormGroup.addControl(
      'listItemInput',
      new FormControl(
        { value: this.selectedItems, disabled: this.input.disabled },
        this.input.validators
      )
    );
  }

  removeItemFromList(indexToRemove) {
    this.markAsTouched();
    if (!this.disabled) {
      this.selectedItems = this.selectedItems.filter(
        (_, currentIndex) => currentIndex !== indexToRemove
      );
      this.listItemsFormGroup.setValue({ listItemInput: this.selectedItems });
      this.onChange(this.selectedItems);
    }
  }

  listItemsChangedByDropDown({ value: selectedValues }) {
    if (this.listItemsFormGroup.invalid) {
      this.listItemsFormGroup.markAllAsTouched();
      return;
    }
    this.markAsTouched();
    if (!this.disabled) {
      this.selectedItems = selectedValues;
      this.listItemsFormGroup.setValue({ listItemInput: selectedValues });
      this.onChange(this.selectedItems);
    }
  }

  onTextBoxBlur({ target: { value: rawValue } }, currentIndex) {
    this.markAsTouched();
    if (!this.disabled) {
      const currentValue = rawValue.trim();
      if (currentValue == 'CustomTag' || currentValue == '') {
        if (currentValue == 'CustomTag') {
          this.selectedItems.pop();
        } else {
          this.selectedItems.slice(currentIndex, 1);
        }
        this.onChange(this.selectedItems);
      } else {
        if (currentValue != this.selectedItems[currentIndex]) {
          this.selectedItems[currentIndex] = currentValue;
          this.listItemsFormGroup.setValue({
            listItemInput: this.selectedItems,
          });
          this.onChange(this.selectedItems);
        }
      }
    }
  }

  addTempItemFromTextBox() {
    this.selectedItems.push('CustomTag');
  }

  changeOrder(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedItems,
      event.previousIndex,
      event.currentIndex
    );
  }

  save() {
    if (this.listItemsFormGroup.valid) {
      this.onSave.emit();
    }
  }

  writeValue(items: any) {
    this.selectedItems = items;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
