<div class="d-flex" *ngIf="data.isProfileModal" [ngClass]="data.isProfileModal ? 'my-profile-modal' : 'welcome-window'">
    <label *ngIf="data.isProfileModal" class="white">Roles that describe your work the best</label>
    <mat-icon class="fs-18 white cursor-pointer" (click)="onDismiss()">close</mat-icon>
</div>
<div class="main-con d-flex flex-column gap-4" [ngClass]="data.isProfileModal?'p-20 my-profile-modal-height':''">
    <div *ngIf="!data.isProfileModal && isStaffingRoleMandatory" class="pt-40"></div>
    <div *ngIf="!data.isProfileModal && !isStaffingRoleMandatory" class="d-flex justify-content-end pt-20">
        <mat-icon class="fs-18 gray cursor-pointer" (click)="onDismiss()">close</mat-icon>
    </div>
    <div *ngIf="data.isProfileModal" class="body-large">
        Define the kind of work you do, as it helps us match you with the relevant work opportunities.
        Make sure to keep it up-to-date.
    </div>
    
    <div *ngIf="!data.isProfileModal" class="item-center flex-column">
        <span class="wave-emoji">{{ waveEmoji }}</span>
        <label class="h3 black">Tell us about your work</label>
        <label class="caption gray">This helps us match you with the relevant work opportunities.</label>
    </div>
    <mat-divider></mat-divider>
    <form class="d-flex flex-column modal-body-con" [formGroup]="staffingRoleForm">
        <div class="d-flex flex-column modal-body-con">
            <label class="body black mb-1">Which role(s) describes your work the best?</label>
            <app-searchable-multiselect *ngIf="staffingRoleInput.options.length" [input]="staffingRoleInput"  [customPanelClass]="'staffing-role-modal'"
                class="d-inline-block" (selectionChange)="onMultiAutoCompleteChange($event)">
            </app-searchable-multiselect>
            <label *ngIf="selectedRoles?.length <= maxLimit" class="caption gray">Upto {{maxLimit}}</label> 
            <mat-error *ngIf="selectedRoles?.length > maxLimit" class="caption">Maximum {{maxLimit}} roles allowed.</mat-error>

            <div class="mt-3">
                <mat-chip-list>
                    <mat-chip *ngFor="let item of selectedRoles" class="chip"
                        (removed)="onRemove(item)">{{item.tagValue}}
                        <button matChipRemove><mat-icon>close</mat-icon></button>
                    </mat-chip>
                </mat-chip-list>
            </div>

            <!-- Request for new Role -->
            <div *ngIf="showDesiredRoles">
                <div class="item-between mt-3">
                <label class="body black">Enter desired role(s)</label>
                <mat-icon class="fs-18 gray tag-close mt-1 cursor-pointer" (click)="onRequestRoleClose()">close</mat-icon>
                </div>
                <app-multi-select-chip [input]="desiredRolesInput" formControlName="desiredRoles">
                </app-multi-select-chip>
            </div>
        </div>
        <!-- action section -->
        <div class="py-10" [ngClass]="!isStaffingRoleMandatory || data.isProfileModal ? 'item-between' : 'item-center'">
            <button mat-stroked-button class="secondary-button" *ngIf="!isStaffingRoleMandatory || data.isProfileModal" (click)="onDismiss()">Dismiss</button>
            <button mat-stroked-button class="primary-button" [disabled]="isMaxLimitReached || isFormEmpty || this.staffingRoleForm?.get('desiredRoles').value?.length > 1" (click)="onSave()" matTooltipPosition="above"
            [matTooltip]="isMaxLimitReached ? 'Maximum ' + maxLimit + ' roles allowed' : isFormEmpty ? 'Choose/Request at least one staffing Role': this.staffingRoleForm?.get('desiredRoles').value?.length > 1 ? 'You can request only one role':''">Save</button>
        </div>
    </form>

</div>
