import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { SkillDetailsModalComponent } from '../../my-skills/ai-resume-upload/modals/skill-details-modal/skill-details-modal.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-proficiency-slider-overlay',
  templateUrl: './proficiency-slider-overlay.component.html',
  styleUrls: ['../../my-skills/ai-resume-upload/components/proficiency-slider/proficiency-slider.component.scss', './proficiency-slider-overlay.component.scss'
  ]
})
export class ProficiencySliderOverlayComponent implements OnInit {
  @Input() client: Client;
  @Input() key: string;
  @Input() title: string;
  @Input() editable: boolean = false;
  @Input() skillItem: any;
  @Input() noTooltip = false;
  @Input() color = 'var(--primary-color)';
  @Input() greyedOut: boolean = false;
  @Input() onEndorsementPage: boolean = false;
  @Input() header: string;
  @Output() onSetValue = new EventEmitter();

  public proficiencyLevels = [];
  maxRating: number = 5;
  public skillForm: FormGroup;
  settings: any;

  constructor(
    private ds: DataService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.initializeSettings();
    this.createSkillForm();
  }

  private initializeSettings() {
    const smsSettings = this.ds.client.smsSettings;
    const skillRating = smsSettings.skillRating;
    if (skillRating === 'Stars 1-5') {
      for (let i = 1; i <= 5; i++) {
        this.maxRating = 5;
        this.proficiencyLevels.push(smsSettings[`skillToolTipLabel${i}`])
      }
    } else {
      for (let i = 1; i <= 4; i++) {
        this.maxRating = 4;
        this.proficiencyLevels.push(smsSettings[`skillToolTipLabel${i}`])
      }
    }
    this.settings = this.ds.client.smsSettings.categoryWiseSettings.find(
      (item: any) => item.categoryId == this.skillItem.skillCategory.categoryId
    );
  }

  public updateSliderProperties() {
      const slider = document.querySelector(`.proficiency-slider-range_${this.key}_${this.skillItem.skillItemId}`) as HTMLElement;
      slider.style.setProperty('--max-rating', this.maxRating.toString());

    };


  public updateProficiency(key: string) {
    const slider = document.querySelector(`.proficiency-slider-range_${key}_${this.skillItem.skillItemId}`) as HTMLElement;
    slider.style.setProperty('--value', this.getFormControlValue(key).toString());
    this.skillItem[key] = this.getFormControlValue(key);
    this.updateTicks();
    this.onSetValue.emit({ event: 'onSetRating', data: { key: this.key, skillItem: this.skillItem, value: this.skillItem[this.key] || 0 } });
  }

  public getFormControlValue(controlName: string): number{
    return this.skillForm.get(controlName)?.value || 1;
  }

  private createSkillForm() {
    this.skillForm = this.fb.group({});
    this.addControlForSkillItem(this.skillItem);
  }

  private addControlForSkillItem(skillItem) {
      let value = '';
      if (skillItem[this.key] && skillItem[this.key] !== '') {
        value = skillItem[this.key];
      }
      this.skillForm.addControl(this.key, new FormControl(value));
  }

  public updateTicks(): void {
      const value = this.getFormControlValue(this.key);
      const ticks = document.querySelectorAll(`.proficiency-slider-range_${this.key}_${this.skillItem.skillItemId} ~ .proficiency-slider-ticks .tick`);
      ticks.forEach((tick, index) => {
        tick.classList.toggle('active', index + 1 < Number(value));
        tick.classList.toggle('hidden', index + 1 === Number(value));
      });
  }

  public showSkillOverlayDetails(): void {
    const dialogRef = this.dialog.open(SkillDetailsModalComponent, {
      width: (600 > (0.65 * window.innerWidth) ? (0.65 * window.innerWidth) : 600) + 'px',
      height: (0.65 * window.innerHeight) + 'px',
      panelClass: 'resume-builder'
    });
    dialogRef.componentInstance.skillData = this.skillItem;
    dialogRef.componentInstance.onEvent.pipe(take(1)).subscribe((data: any) => {
      if (data.status === 'close') {
        dialogRef.close();
      }
    });
  }
}
