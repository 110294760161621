<div class="notification-dialog" style="padding:0px;">
  <div class="top">
    <div class="top-inner">
      <div class="body-large white">{{ notificationData?.userGroupId ? 'Edit User Group': 'Create New User Group'  }}</div>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>
  <div class="notification-dialog-outer">
    <div class="input-con-full" [hidden]="loadingFilter">
      <form [formGroup]="editForm">
        <!--================= User Group Name =================-->
        <mat-form-field appearance="outline" class="input-form-field">
          <mat-label>User Group Name</mat-label>
          <input placeholder="Enter a memorable group name" matInput required autocomplete="off"
            formControlName="groupName" />
        </mat-form-field>
        <!--================= Description =================-->
        <mat-form-field appearance="outline" class="input-form-field">
          <mat-label>Description</mat-label>
          <textarea class="description-textarea" matInput required autocomplete="off"
            formControlName="description"></textarea>
        </mat-form-field>
      </form>
      <div class="form-divider"></div>
      <!--================= Add Filter Button =================-->
      <app-search-dropdown *ngIf="filterDropDownData" #ddFilters [data]="filterDropDownData"
        (change)="onFilterChange($event)">
      </app-search-dropdown>
      <!--================= Filters  =================-->
      <div *ngFor="let filter of filters;let last = last;">
        <!--================= Search and select  =================-->
        <div *ngIf="filter.type == 'search'" class="filter-input-form-field">
          <div class="filter-header">
            <div class="h5 gray">{{ filter.name }}</div>
            <mat-icon (click)="removeFilter(filter)">close</mat-icon>
          </div>
          <app-search [opts]="filter.searchOpts" (onSearchEnter)="onSeachEnter($event)"
            (onSearchResult)="onSearchResult($event, filter)">
          </app-search>
          <div *ngIf="filter.selectedValues.length" class="selected-search-con">
            <div class="selected-search-value" *ngFor="let value of filter.selectedValues">
              <div class="h5 black">{{ value?.name }}</div>
              <mat-icon (click)="removeFilterValue(filter,value)">close</mat-icon>
            </div>
          </div>
        </div>
        <!--=================  Select  =================-->
        <div *ngIf="filter.type == 'select'" class="filter-input-form-field">
          <div class="filter-header">
            <div class="h5 gray">{{ filter.name }}</div>
            <mat-icon (click)="removeFilter(filter)">close</mat-icon>
          </div>
          <mat-form-field appearance="outline">
            <mat-select (selectionChange)="onFilterValueChange()" [required]="true" [multiple]="filter.multiSelect"
              [(ngModel)]="filter.selectedValues">
              <mat-option *ngFor="let option of filter.availableValues" [value]="filter.dataKey == 'designationLevel' ? option.value :  option">
                {{ filter.dataKey == 'designationLevel' ? option.name :  option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!--================= Date =================-->
        <div *ngIf="filter.type == 'date'" class="filter-input-form-field">
          <div class="filter-header">
            <div class="h5 gray">{{ filter.name }}</div>
            <mat-icon (click)="removeFilter(filter)">close</mat-icon>
          </div>
          <div class="input-two-fields">
            <mat-form-field appearance="outline" class="date-input">
              <mat-label>Start date</mat-label>
              <mat-icon matPrefix>date_range</mat-icon>
              <input matInput (dateChange)="onFilterValueChange()" placeholder="Select start date"
                [(ngModel)]="filter.minDate" [matDatepicker]="picker1" required autocomplete="off"
                (focus)="picker1.open()" />
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" class="date-input">
              <mat-label>End date</mat-label>
              <mat-icon matPrefix>date_range</mat-icon>
              <input matInput (dateChange)="onFilterValueChange()" placeholder="Select end date"
                [(ngModel)]="filter.maxDate" [matDatepicker]="picker2" required autocomplete="off"
                (focus)="picker2.open()" />
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <!--================= Number =================-->
        <div *ngIf="filter.type == 'number'" class="filter-input-form-field">
          <div class="filter-header">
            <div class="h5 gray">{{ filter.name }}</div>
            <mat-icon (click)="removeFilter(filter)">close</mat-icon>
          </div>
          <div class="input-two-fields">
            <mat-form-field appearance="outline">
              <mat-label>Minimum</mat-label>
              <input required (change)="onFilterValueChange()" [(ngModel)]="filter.min" matInput type="number" min="0"
                max="40" autocomplete="off" appTwoDigitDecimal>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Maximum</mat-label>
              <input required (change)="onFilterValueChange()" [(ngModel)]="filter.max" matInput type="number" max="40"
                autocomplete="off" appTwoDigitDecimal>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="!last" class="and-label h5 gray">AND</div>
      </div>
    </div>
  </div>
  <div class="button-layout">
    <div class="h5 gray">{{ totalUsersCnt }} users</div>
    <div>
      <button class="secondary-button-warn delete-button" (click)="onSave(true)" *ngIf="config.enableDelete">DELETE</button>
      <button class="primary-button" (click)="onSave()" [disabled]="!this.filters.length || !editForm.valid">{{ notificationData?.userGroupId ?  'SAVE CHANGES' :
        'CREATE USER GROUP' }}</button>
    </div>
  </div>
</div>
