import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Client } from 'src/app/models/client.model';
import { User } from 'src/app/models/user.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { ValidateEmail, ValidateHexColor, ValidateMobile, ValidateString } from 'src/app/services/validation.service';
import { SvgIconComponent } from '../../common-components/svg-icon/svg-icon.component';
import { Subscription } from 'rxjs';
import { ThemeService } from 'src/app/services/theme.service';
import { take } from 'rxjs/operators';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ]
})
export class CompanyInfoComponent implements OnInit {

  user: User;
  client: Client;
  featuresInputs = [];
  infoForm: FormGroup;
  brandForm: FormGroup;
  featuresForm: FormGroup;
  canEdit = false;
  minLicenseStartDt;
  maxLicenseStartDt;
  minLicenseEndDt;
  maxLicenseEndDt;
  @ViewChild('fileInputSquare') fileInputSquare: ElementRef;
  @ViewChild('fileInputRect') fileInputRect: ElementRef;
  @ViewChild('fileInputLoadingGIF') fileInputLoadingGIF: ElementRef;
  @ViewChild('fileInputSquareRMS') fileInputSquareRMS: ElementRef;
  @ViewChild('fileInputRectRMS') fileInputRectRMS: ElementRef;
  @ViewChild('fileInputSquareOP') fileInputSquareOP: ElementRef;
  @ViewChild('fileInputRectOP') fileInputRectOP: ElementRef;

  @ViewChild('logoSquare') logoSquare: SvgIconComponent;
  @ViewChild('logoRect') logoRect: SvgIconComponent;
  @ViewChild('loadingGIF') loadingGIF: SvgIconComponent;
  @ViewChild('logoSquareRMS') logoSquareRMS: SvgIconComponent;
  @ViewChild('logoRectRMS') logoRectRMS: SvgIconComponent;
  @ViewChild('logoSquareOP') logoSquareOP: SvgIconComponent;
  @ViewChild('logoRectOP') logoRectOP: SvgIconComponent;

  uploadSub: Subscription = new Subscription();
  getDataTimer;
  logoData: any = {};
  public companyAliasNamesInput = {
    key: 'companyAliasNames',
    title: 'Alias Name',
    icon: 'location_city',
    value: [],
    placeholder: 'Add alias name & hit Enter',
    errorText: 'Minimum 3 characters required',
    validators: [ValidateString(3)]
  }

  constructor(private themeService: ThemeService, private ds: DataService, private weds: WaitErrorDialogsService, private fb: FormBuilder) { }

  ngOnInit(): void {

    this.user = this.ds.user;
    this.canEdit = this.user.role.globalAdmin ? this.user.role.globalAdmin.clients == 'Edit' : this.user.role.admin && this.user.role.admin.companyInfo == 'Edit';
    this.infoForm = this.fb.group({});
    this.minLicenseStartDt = new Date(2021, 1, 1); this.maxLicenseStartDt = new Date(new Date().getTime() + 86400000 * 30);
    this.minLicenseEndDt = new Date(new Date().getTime()); this.maxLicenseEndDt = new Date(new Date().getTime() + 86400000 * 3650);
    this.infoForm.addControl('name', new FormControl({ value: '', disabled: !this.canEdit }, [Validators.required, ValidateString(3)]));
    this.infoForm.addControl('legalName', new FormControl({ value: '', disabled: !this.canEdit }, [Validators.required, ValidateString(3)]));
    this.infoForm.addControl('companyAliasNames', new FormControl({ value: [], disabled: !this.canEdit }));
    this.infoForm.addControl('licenseStartDt', new FormControl({ value: '', disabled: !this.canEdit }, [Validators.required]));
    this.infoForm.addControl('licenseEndDt', new FormControl({ value: '', disabled: !this.canEdit }, [Validators.required]));
    this.infoForm.addControl('address', new FormControl({ value: '', disabled: !this.canEdit }, [Validators.required, ValidateString(3)]));
    this.brandForm = this.fb.group({
      primaryColorLightest: new FormControl('', [ValidateHexColor]),
      primaryColorLight: new FormControl('', [ValidateHexColor]),
      primaryColor: new FormControl('', [ValidateHexColor]),
      primaryColorDark: new FormControl('', [ValidateHexColor]),
      accentColor: new FormControl('', [ValidateHexColor]),
      accentColorDark: new FormControl('', [ValidateHexColor]),
      disablePrismLogo: new FormControl('')
    });
    this.featuresForm = this.fb.group({});
    this.getData(this.ds.currentAdminClientId);
    this.client = new Client();
  }
  ngOnDestroy(): void {
    this.uploadSub.unsubscribe();
    if (this.getDataTimer) clearTimeout(this.getDataTimer);
  }

  //--------------------------Get data-----------------------
  getData(clientId) {
    this.weds.showDialog({ type: 'wait', code: -2 });
    this.ds.searchData('admin/getCompanyInfo', { clientId: clientId }).pipe(take(1)).subscribe((rs: any) => {
      this.weds.closeDialog();
      if (rs.status == 'Success') {
        this.client = new Client().deserialize(rs.client);
        localStorage.setItem("currentClient", JSON.stringify(this.client));
        this.ds.client = this.client;
        // this.ds.updateAliasesForMenuItems(this.client);
        if (!this.client.contacts) this.client.contacts = [{ id: Math.round(Math.random() * 100000000), name: '', email: '', mobile: '' }];
        this.buildForms();
      } else {
        this.weds.showDialog({ type: 'generic', code: rs.error ? rs.error.code : 99 });
      }
    }, (err) => {
      this.getDataTimer = setTimeout(() => {
        if (!this.client.clientId) this.getData(clientId);
      }, 10000);
    });
  }

  //------------------------Build input forms------------------
  buildForms() {

    ['name', 'legalName', 'address','companyAliasNames'].map((key) => { 
      this.infoForm.controls[key].setValue(this.client[key] || '')
      if(key === 'companyAliasNames') {
        this.companyAliasNamesInput =  { ...this.companyAliasNamesInput, value: this.client[key] || []}
      }
     });
    ['licenseStartDt', 'licenseEndDt'].map((key) => { this.infoForm.controls[key].setValue(moment(this.client[key] || '', 'DD MMM YYYY')) });
    this.client.contacts.map((c) => { if (!c.id) c.id = Math.round(Math.random() * 1000000); this.addContactFormControl(c); })
    this.buildBrandForms();
    if (this.client.logoSquare) this.logoSquare.src = this.client.logoSquare;
    if (this.client.logoRect) this.logoRect.src = this.client.logoRect;
    if (this.client.loadingGIF?.loc) this.loadingGIF.src = this.client.loadingGIF.loc;
    if (this.client.logoSquareRMS?.loc) this.logoSquareRMS.src = this.client.logoSquareRMS.loc;
    if (this.client.logoRectRMS?.loc) this.logoRectRMS.src = this.client.logoRectRMS.loc;
    if (this.client.logoSquareOP?.loc) this.logoSquareOP.src = this.client.logoSquareOP.loc;
    if (this.client.logoRectOP?.loc) this.logoRectOP.src = this.client.logoRectOP.loc;
    let inputs: any[] = [
      { key: 'admin', titleText: 'Access to admin features', title: 'Grant Access?', icon: 'settings_suggest', options: ['No', 'Yes'], class: 'input-con-full', value: this.client.features.admin.companyInfo ? 'Yes' : 'No' },
    ]
    for (let key of this.ds.features.admin._sequence) {
      let f = this.ds.features.admin[key];
      inputs.push({ key: key, title: f.menuName, icon: f.icon, options: f.rights, value: this.client.features.admin[key], accessKey: 'admin' });
    }
    inputs.push({ key: 'sms', titleText: 'Access to SMS features', title: 'Grant Access?', icon: 'engineering', options: ['No', 'Yes'], class: 'input-con-full', value: this.client.features.sms.mySkills ? 'Yes' : 'No' });
    for (let key of this.ds.features.sms._sequence) {
      let f = this.ds.features.sms[key];
      //if (key!= 'proficiencyRecommendation')
      inputs.push({ key: key, title: f.menuName, icon: f.icon, options: f.rights, value: this.client.features.sms[key], accessKey: 'sms' });
    }
    for (let input of inputs) {
      this.featuresForm.addControl(input.key, new FormControl({ value: input.value, disabled: !this.canEdit }));
    }
    var profRecommendation = inputs.find(function (data) { return data.key == 'proficiencyRecommendation' });
    var allowMachineRating = inputs.find(function (data) { return data.key == 'allowMachineRating' });
    if (profRecommendation && profRecommendation.value != 'Edit') {
      allowMachineRating.show = false
    }
    else {
      if (allowMachineRating)
        allowMachineRating.show = true;
    }
    this.featuresInputs = inputs;
  }
  
  buildBrandForms() {
    const formControls = [
      { controlName: 'primaryColorLightest', value: 'primaryColorLightest' },
      { controlName: 'primaryColorLight', value: 'primaryColorLight' },
      { controlName: 'primaryColor', value: 'primaryColor' },
      { controlName: 'primaryColorDark', value: 'primaryColorDark' },
      { controlName: 'accentColor', value: 'accentColor' },
      { controlName: 'accentColorDark', value: 'accentColorDark' },
      { controlName: 'disablePrismLogo', value: 'disablePrismLogo' }
    ];
    
    formControls.forEach(({ controlName, value }) => {
      this.brandForm.controls[controlName].setValue(this.client[value]);
    });
  }

  addContactFormControl(c) {
    this.infoForm.addControl('name_' + c.id, new FormControl({ value: c.name, disabled: !this.canEdit }, [Validators.required, ValidateString(3)]));
    this.infoForm.addControl('email_' + c.id, new FormControl({ value: c.email, disabled: !this.canEdit }, [Validators.required, ValidateEmail]));
    this.infoForm.addControl('mobile_' + c.id, new FormControl({ value: c.mobile, disabled: !this.canEdit }, [Validators.required, ValidateMobile]));
  }
  removeContactFormControl(c) {
    this.infoForm.removeControl('name_' + c.id);
    this.infoForm.removeControl('email_' + c.id);
    this.infoForm.removeControl('mobile_' + c.id);
  }
  //-----------------------Company info form functions-------------
  addContact() {
    let c = { id: Math.round(Math.random() * 100000000), name: '', email: '', mobile: '' };
    this.client.contacts.push(c);
    this.addContactFormControl(c);
  }
  deleteContact(contact) {
    for (let i = 0; i < this.client.contacts.length; i++) {
      if (contact.id == this.client.contacts[i].id) this.client.contacts.splice(i--, 1);
    }
    this.removeContactFormControl(contact);
  }
  onSaveInfo() {

    if (this.infoForm.invalid) return;
    let data = this.infoForm.value;
    data.companyAliasNames = !data.companyAliasNames ? [] : data.companyAliasNames;
    data.licenseStartDt = data.licenseStartDt._isAMomentObject ? data.licenseStartDt : moment(data.licenseStartDt, 'DD MMM YYYY');
    data.licenseEndDt = data.licenseEndDt._isAMomentObject ? data.licenseEndDt : moment(data.licenseEndDt, 'DD MMM YYYY');
    if (data.licenseStartDt.valueOf() > data.licenseEndDt.valueOf()) { this.weds.showDialog({ type: 'generic', code: 129 }); return; }
    data.licenseStartDt = data.licenseStartDt.format('DD MMM YYYY');
    data.licenseEndDt = data.licenseEndDt.format('DD MMM YYYY');
    data.type = 'basicInfo';
    data.contacts = [];
    this.client.contacts.map((c) => { data.contacts.push({ name: this.infoForm.controls['name_' + c.id].value, email: this.infoForm.controls['email_' + c.id].value, mobile: this.infoForm.controls['mobile_' + c.id].value }) })
    this.saveData(data);
  }
  //-----------------------Brand info form functions-------------
  selectFile(fileInputRef: string) {
    if (!this.canEdit) return;
    this[fileInputRef]?.nativeElement?.click();
  }

  onFileSelected(event, imageRef: string) {
    let file = event.target.files[0];
    if (!file || !file.type || (file.type.search('png') < 0 && file.type.search('gif') < 0)) return;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (_event) => {
      this[imageRef].nativeElement.src = fileReader.result;
    }
    this.uploadFile(event.target.files, imageRef);
  }

  uploadFile(file, imageRef) {
    let dialogData = { type: 'wait', title: 'Uploading', message: 'Please wait while the file is uploaded', showProgress: true, progress: 0 };
    this.weds.showDialog(dialogData);
    this.uploadSub = this.ds.uploadFiles(file, this.ds.currentAdminClientId, ['image/png', 'image/gif'], 10).subscribe((rs: any) => {
      if (rs.type == 1) {
        dialogData.progress = Math.round(rs.loaded * 100 / rs.total);
      }
      else if (rs.status == 200 && rs.body) {
        rs = rs.body;
        this.uploadSub.unsubscribe();
        this.weds.closeDialog();
        if (rs.status == 'Success') {
          this.logoData[imageRef] = rs.files[0];
          this[imageRef].nativeElement.src = rs.files[0].loc;
        } else {
          this.weds.closeDialog();
          this.weds.showDialog({ type: 'generic', code: rs.error.code });
        }
      } else if (rs.type !== 0 && !rs.type && rs.status != 200) {
        this.weds.closeDialog();
        this.weds.showDialog({ type: 'generic', code: 0 });
      }
    }, (err) => {
      this.uploadSub.unsubscribe();
    });
  }

  onSaveBrand() {
    if (this.brandForm.valid) {
      let data: any = { ...this.brandForm.value, ...this.logoData, type: 'brand', clientId: this.client.clientId };
      this.saveData(data);
    }
  }


  //-----------------------Features form functions-------------
  onSaveFeatures() {
    if (this.featuresForm.invalid) return;
    let data = this.featuresForm.value;
    data.type = "features";
    this.saveData(data);
  }
  saveData(data) {
    data.clientId = this.client.clientId;
    this.weds.showDialog({ type: 'wait', code: -5 });
    this.ds.saveData('admin/saveCompanyInfo', data).pipe(take(1)).subscribe((rs: any) => {
      this.weds.closeDialog();
      if (rs.status == 'Success') {
        setTimeout(() => { this.weds.showDialog({ type: 'generic', code: -1 }); }, 200);
        if (!this.user.role.globalAdmin) {
          this.ds.saveClient({ ...this.client, ...data });
          this.themeService.setClientTheme();
        }
      } else {
        this.weds.showDialog({ type: 'generic', code: rs.error ? rs.error.code : 99 });
      }
    });
  }
}

