<div class="my-growth-card d-flex flex-fill flex-column">
  <div class="card-header d-flex align-items-center mt-2" [ngClass]="{'active': myGrowthPlanObj.status === 'ACTIVE', 'in-review': myGrowthPlanObj.status !== 'ACTIVE'}" *ngIf="!hideHeader">
    <div class="text">
      <div class="main-text">
        {{ myGrowthPlanObj.status === 'ACTIVE'
            ? GoalStatusHeader.GOAL_ACTIVE_TITLE
            : myGrowthPlanObj.status === 'GOAL SETTING'
            ? totalGoalsValue + (totalGoalsValue > 1 ? ' Goal(s) ' : ' Goal ') + GoalStatusHeader.GOAL_SET_TITLE
            : GoalStatusHeader.DEFAULT_GOAL_TITLE }}
      </div>
      <div class="sub-text">
        {{ myGrowthPlanObj.status === 'ACTIVE'
            ? GoalStatusHeader.GOAL_ACTIVE_DESC
            : myGrowthPlanObj.status === 'GOAL SETTING'
            ? GoalStatusHeader.GOAL_SET_DESC
            : GoalStatusHeader.DEFAULT_GOAL_DESC }}
      </div>
    </div>
    <div class="nav-icon clickable">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
  <div class="data-con card-body mt-0 border-box">
    <div class="icon">
      <button [ngClass]="{activeProgressIcon: myGrowthPlanObj.status === 'ACTIVE'}" class="btn-explore item-center">
        <div>
          <mat-icon class="active-icon" [ngClass]="{activeExploreIcon: myGrowthPlanObj.status === 'ACTIVE'}" class="d-flex item-center">explore</mat-icon>
        </div>
      </button>
    </div>
    <div class="goal-slider slider-container pt-0">
      <div class="button-label mb-1">
        <div class="right-label">
          {{myGrowthPlanObj.name}}
        </div>
        <div class="active-status border-radius-4">
          <span class="h6 text-uppercase">{{myGrowthPlanObj.status}}</span>
        </div>
      </div>

      <div class="slider mt-2 item-center w-100">
        <div class="progress-bar">
          <div class="progress" [ngClass]="{activeProgress: myGrowthPlanObj.status === 'ACTIVE'}" [ngStyle]="{ width: calculateActivePhasePosition() + '%' }"></div>
          <div class="thumb" [ngStyle]="{ left: calculateActivePhasePosition() + '%' }"></div>
        </div>
      </div>

      <div class="info icon-sec d-flex justify-content-between">
        <div class="goal-indicator d-flex">
          <ng-template #goalIndicator let-value="value" let-icon="icon" let-color="color" let-showBar="showBar">
            <div class="d-flex">
              <mat-icon class="mr-1" [ngStyle]="{'color': color }">{{ icon }}</mat-icon>
              <span class="val">{{ value }}</span>
            </div>
            <div *ngIf="showBar" class="vl"></div>
          </ng-template>

          <ng-container *ngIf="myGrowthPlanObj.goalsProgressStatusAndCount['GOAL_SET'] !== null && myGrowthPlanObj.goalsProgressStatusAndCount['GOAL_SET'] >= 0">
            <ng-container *ngTemplateOutlet="goalIndicator; context: { value: myGrowthPlanObj.goalsProgressStatusAndCount['GOAL_SET'], icon: 'album', color: getColor('goalsSet'), showBar: hasNextGoal('GOAL_SET') }"></ng-container>
          </ng-container>
          <ng-container *ngIf="myGrowthPlanObj.goalsProgressStatusAndCount.inReview !== null && myGrowthPlanObj.goalsProgressStatusAndCount.inReview >= 0">
            <ng-container *ngTemplateOutlet="goalIndicator; context: { value: myGrowthPlanObj.goalsProgressStatusAndCount.inReview, icon: 'pending', color: getColor('inReview'), showBar: hasNextGoal('inReview') }"></ng-container>
          </ng-container>
          <ng-container *ngIf="myGrowthPlanObj.goalsProgressStatusAndCount['COMPLETED'] !== null && myGrowthPlanObj.goalsProgressStatusAndCount['COMPLETED'] >= 0">
            <ng-container *ngTemplateOutlet="goalIndicator; context: { value: myGrowthPlanObj.goalsProgressStatusAndCount['COMPLETED'], icon: 'offline_pin', color: getColor('completed'), showBar: hasNextGoal('COMPLETED') }"></ng-container>
          </ng-container>
          <ng-container *ngIf="myGrowthPlanObj.goalsProgressStatusAndCount['DELAYED'] !== null && myGrowthPlanObj.goalsProgressStatusAndCount['DELAYED'] >= 0">
            <ng-container *ngTemplateOutlet="goalIndicator; context: { value: myGrowthPlanObj.delayed, icon: 'watch_later', color: getColor('delayed'), showBar: false }"></ng-container>
          </ng-container>
          <ng-container *ngIf="myGrowthPlanObj.userWithoutGoals">
            <div class="vl" *ngIf="hasValue()"></div>
            <div class="item-center val"><mat-icon class="warning mr-1">warning</mat-icon> <span class="text-no-goal mb-1">{{myGrowthPlanObj.userWithoutGoals}} team members without goals</span></div>
          </ng-container>
        </div>
        <div class="days-left">
          {{myGrowthPlanObj.daysLeft}} <span class="text">days left</span>
        </div>
      </div>

    </div>
  </div>
</div>
