<div class="d-flex flex-column align-items-start gap-12" *ngIf="allCards.length">
    <div class="item-center" *ngIf="!config.hideViewMoreBtn">
        <mat-icon *ngIf="config?.headerIcon" class="translateY-2px fs-20">{{config?.headerIcon}}</mat-icon>
        <label class="h4">{{config?.groupName}}</label>
    </div>

    <div class="d-flex flex-column align-items-start gap-3 custom-width" [ngClass]="{
        'card-group-box': config?.showGrayBG,
        'full-height': config?.hideViewMoreBtn
    }">
        <div class="d-flex gap-3 flex-wrap w-100" #cardHolder>
            <div *ngFor="let card of cardsShown">
                <app-progress-card [data]=card></app-progress-card>
            </div>
        </div>

        <div *ngIf="allCards.length > numberOfCardsToShow && !isLoading && !config.hideViewMoreBtn">
            <ng-container *ngIf="allCards.length <= 12 ; else viewAllBtn">
                <button mat-button class="secondary-button h6 item-center"
                    (click)="toggleContainerExpansion()">
                    <label class="cursor-pointer"> {{ isExpanded ? 'Show Less' : 'Show More' }}</label>
                    <mat-icon>{{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
                </button>
            </ng-container>

            <ng-template #viewAllBtn >
                <button mat-button class="secondary-button h6 item-center"
                    (click)="redirectToViewAll()">
                    <label class="cursor-pointer">View All  {{ totalDataCount }} learnings</label>
                    <mat-icon class="translateY-2px fs-18 cursor-pointer">east</mat-icon>
                </button>
            </ng-template>
        </div>

        <div *ngIf="!isLoading && !cardsShown.length" class="no-data h4 gray m-0 w-100">
            <img src="../../../../assets/nodata.png" />
            <div>Sorry, there's no data available for you at this moment.</div>
        </div>
    </div>

</div>