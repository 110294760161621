<div class=" bottom-con caption gray" *ngIf="data?.totalDataCnt && data?.totalDataCnt != 0">
    <div class="limit-con">
        <mat-select [value]="limit" (selectionChange)="onLimitChange($event)">
            <mat-option *ngFor="let num of numPageOptions" [value]="num">{{num}}
            </mat-option>
        </mat-select>
        <div>rows per page</div>
    </div>
    <div class="offset-con" *ngIf="limit < data.totalDataCnt">
        <span style="margin-right:8px;">Showing {{startOfRange}} to
            {{endOfRange}} of {{data.totalDataCnt}} {{itemTitle}}</span>
        <mat-icon *ngIf="pageNumber>1" (click)="onNextPrev('first')">arrow_left</mat-icon>
        <mat-icon *ngIf="pageNumber>1" (click)="onNextPrev('prev')">keyboard_arrow_left</mat-icon>
        <div *ngFor="let num of numPages" class="page-number-button"
            [ngClass]="{'page-number-button-selected':num==pageNumber}" (click)="onPageNumberClick(num)">{{num}}
        </div>
        <mat-icon *ngIf="pageNumber*limit<data.totalDataCnt" (click)="onNextPrev('next')">
            keyboard_arrow_right
        </mat-icon>
        <mat-icon *ngIf="pageNumber*limit<data.totalDataCnt" (click)="onNextPrev('last')">arrow_right
        </mat-icon>
    </div>
</div>