import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-timeline-selector',
  templateUrl: './timeline-selector.component.html',
  styleUrls: ['./timeline-selector.component.scss']
})
export class TimelineSelectorComponent implements OnInit {
  @Input() data: any;//fromDt, toDt, minFromDt, maxToDt, useQuarters
  @Output() onEvent = new EventEmitter();
  useQuarters = true;
  dates: any[] = [];
  timeText = "";
  hideQuarters = false;
  selectedFromDt;
  selectedToDt;
  selectingToDt = false;
  allowSingleQuarter = false;
  disableFutureSelection = false;
  quarterToQuartersStartingMonthAndEndingMonthMap = {
    1: ['Jan', 'Mar'],
    2: ['Apr', 'Jun'],
    3: ['Jul', 'Sep'],
    4: ['Oct', 'Dec'],
  };
  constructor() { }

  ngOnInit(): void {
    this.useQuarters = this.data.useQuarters === undefined ? this.useQuarters : this.data.useQuarters;
    if (this.data.hideQuarters || this.data.timelineMode == 'month') this.hideQuarters = true;
    this.allowSingleQuarter = this.data?.allowSingleQuarter ?? false;
    this.disableFutureSelection = this.data?.disableFutureSelection ?? false;
    this.generateDateOptions();
  }
  generateDateOptions() {
    //Initialize dates
    let { minFromDt, maxToDt, fromDt, toDt } = this.data;
    if (minFromDt) minFromDt = moment(this.data.minFromDt, "DD MMM YYYY");
    if (maxToDt) maxToDt = moment(this.data.maxToDt, "DD MMM YYYY");
    if (fromDt) fromDt = moment(this.data.fromDt, "DD MMM YYYY");
    if (toDt) toDt = moment(this.data.toDt, "DD MMM YYYY");
    if (!minFromDt) minFromDt = moment('01 ' + moment().add(1, 'months').format("MMM YYYY"), "DD MMM YYYY").subtract(1, 'days').subtract(5, 'years');
    if (!maxToDt) maxToDt = moment('01 ' + moment().add(1, 'months').format("MMM YYYY"), "DD MMM YYYY").subtract(1, 'days');
    //Generate
    if (this.useQuarters) {
      let m = minFromDt.month() + 1;
      if (m % 3) minFromDt = moment("01 " + minFromDt.subtract(m % 3 - 1, 'months').format("MMM YYYY"), "DD MMM YYYY").subtract(1, 'days');
      m = maxToDt.month() + 1;
      if (m % 3) maxToDt = moment("01 " + maxToDt.add(3 - (m % 3) + 1, 'months').format("MMM YYYY"), "DD MMM YYYY").subtract(1, 'days');
      if (this.allowSingleQuarter && !(toDt && fromDt)) toDt = fromDt =  moment(maxToDt.format("DD MMM YYYY"), "DD MMM YYYY");
      if (!toDt) toDt = moment(maxToDt.format("DD MMM YYYY"), "DD MMM YYYY");
      if (!fromDt) fromDt = moment(maxToDt.format("DD MMM YYYY"), "DD MMM YYYY").subtract(12, 'months');
    } else {
      if (!toDt) toDt = moment(maxToDt.format("DD MMM YYYY"), "DD MMM YYYY");
      if (!fromDt) fromDt = moment(maxToDt.format("DD MMM YYYY"), "DD MMM YYYY").subtract(12, 'months');
    }
    if (!this.selectedFromDt) {
      this.selectedFromDt = fromDt;
      this.selectedToDt = toDt;
    }
    if (this.selectedFromDt && this.selectedToDt && this.selectedFromDt.diff(this.selectedToDt) > 30000) {
      let temp = this.selectedFromDt; this.selectedFromDt = this.selectedToDt; this.selectedToDt = temp;
    }
    if (this.data.timelineMode == 'month') this.selectedFromDt = this.selectedToDt;
    this.dates = [];
    let yearHash = {};
    let dt = moment(maxToDt, "DD MMM YYYY");
    while (dt.diff(minFromDt) >= 0) {
      let year = dt.format("YYYY");
      if (!yearHash[year]) {
        yearHash[year] = true;
        this.dates.push({ type: "year", value: year });
      };
      const disableQuarter = moment(dt).subtract(2, 'month').diff(new Date(), 'months') > 0 ? 'disable' : 'enable';
      let o: any = { type: 'date' + (this.useQuarters ? '-quarter' : '-month'), value: this.useQuarters ? dt.subtract(2, 'months').format("MMM") + " - " + dt.add(2, 'months').format("MMM") : dt.format("MMMM"), selected: '', dt: dt.format("DD MMM YYYY"), ...(this.disableFutureSelection && this.useQuarters && {disable: disableQuarter}) };
      if (this.selectedToDt && dt.format("DD MMM YYYY") == this.selectedToDt.format("DD MMM YYYY")) o.selected = "start";
      else if (this.selectedFromDt && dt.format("DD MMM YYYY") == this.selectedFromDt.format("DD MMM YYYY")) o.selected = "end";
      else if (this.selectedFromDt && dt.diff(this.selectedToDt) < 0 && dt.diff(this.selectedFromDt) > 0) o.selected = "middle";
      if (this.selectedFromDt && dt.format("DD MMM YYYY") == this.selectedFromDt.format("DD MMM YYYY") && this.selectedToDt && dt.format("DD MMM YYYY") == this.selectedToDt.format("DD MMM YYYY")) o.selected = "startend";
      this.dates.push(o);
      dt = moment('01 ' + dt.subtract(this.useQuarters ? 3 : 1, 'months').add(1, 'months').format("MMM YYYY"), "DD MMM YYYY").subtract(1, 'days');
    }
    if(this.data.timelineMode == 'month') {
      this.timeText = this.selectedFromDt.format('MMM YYYY');
    } else {
      if (moment(this.selectedFromDt).isSame(this.selectedToDt)) {
        this.timeText = this.useQuarters
          ? this.getTimelineTextForQuarters(
              this.selectedFromDt,
              this.selectedFromDt
            )
          : moment(this.selectedFromDt).format('MMM YYYY');
      } else {
        this.timeText = this.useQuarters
          ? this.getTimelineTextForQuarters(
              this.selectedFromDt,
              this.selectedToDt
            )
          : this.getTimelineTextForMonths(
              this.selectedFromDt,
              this.selectedToDt
            );
      }
    }
  }
  quartersChange(event) {
    this.useQuarters = event.checked;
    if (this.useQuarters) {
      let m = this.selectedFromDt.month() + 1;
      if (m % 3) this.selectedFromDt = moment("01 " + this.selectedFromDt.subtract(m % 3 - 1, 'months').format("MMM YYYY"), "DD MMM YYYY").subtract(1, 'days');
      m = this.selectedToDt.month() + 1;
      if (m % 3) this.selectedToDt = moment("01 " + this.selectedToDt.add(3 - (m % 3) + 1, 'months').format("MMM YYYY"), "DD MMM YYYY").subtract(1, 'days');
    }
    this.generateDateOptions();
  }
  onSelection(item) {
    if (this.allowSingleQuarter && this.useQuarters) {
      this.selectedFromDt = this.selectedToDt = moment(item.dt, "DD MMM YYYY");
    }
    else {
      if (this.selectingToDt) {
        this.selectingToDt = false;
        this.selectedToDt = moment(item.dt, "DD MMM YYYY");
      } else {
        this.selectedFromDt = moment(item.dt, "DD MMM YYYY");
        if (this.data.timelineMode == 'month') {
          this.selectedToDt = this.selectedFromDt;
        } else {
          this.selectingToDt = true;
          this.selectedToDt = null;
        }
      }
    }
    this.generateDateOptions();
  }
  applyRange() {
    if (!this.selectedFromDt || !this.selectedToDt) return;
    let d: any = { type: 'timeline', fromDt: this.selectedFromDt.format("DD MMM YYYY"), toDt: this.selectedToDt.format("DD MMM YYYY"), useQuarters: this.useQuarters };
    if (d.fromDt == this.data.fromDt && d.toDt == this.data.toDt && this.useQuarters == this.data.useQuarters) d = { type: 'closeDialog' };
    d.timelineText = this.timeText;
    this.onEvent.emit(d);
  }
  getTimelineTextForQuarters(quarterRangeStartDt, quarterRangeEndDt) {
    const actualQuarterRangeEndDt = quarterRangeEndDt || quarterRangeStartDt;
    return `${this.getQuarterStartingMonthInMonthYearFormat(
      quarterRangeStartDt
    )} - ${this.getQuarterEndingMonthInMonthYearFormat(
      actualQuarterRangeEndDt
    )}`;
  }
  getTimelineTextForMonths(monthRangeStartDt, monthRangeEndDt) {
    return `${moment(monthRangeStartDt).format('MMM YYYY')}${
      monthRangeEndDt ? ' - ' + moment(monthRangeEndDt).format('MMM YYYY') : ''
    }`;
  }
  getQuarterStartingMonthInMonthYearFormat(date) {
    return `${
      this.quarterToQuartersStartingMonthAndEndingMonthMap[
        moment(date).quarter()
      ][0]
    } ${moment(date).format('YYYY')}`;
  }
  getQuarterEndingMonthInMonthYearFormat(date) {
    return `${
      this.quarterToQuartersStartingMonthAndEndingMonthMap[
        moment(date).quarter()
      ][1]
    } ${moment(date).format('YYYY')}`;
  }
}
