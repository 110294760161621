import { Injectable } from '@angular/core';
import { ToastComponent } from './toast.component';
import { Toast } from './toast.model';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  private toastComponent: ToastComponent;
  iconMap = {
    "warning": {
      src: "warning-open.svg",
      fill: "var(--color-yellow-40)",
      viewBox: "0 0 60 54",
    },
    "information": {
      src: "info.svg",
      fill: "var(--color-cyan-50)",
      viewBox: "0 0 14 14"
    },
    "success": {
      src: "success-rounded.svg",
      fill: "var(--color-green-dark)",
      viewBox: "0 0 20 20"
    },
    "error": {
      src: "error-rounded-red.svg",
      fill: "var(--color-red-50)",
      viewBox: "0 0 20 20"
    }
  }

  initializeToast(toastComponent: ToastComponent): void {
    this.toastComponent = toastComponent;
  }

  showToast(toast: Toast): number {
    this.setToastData(toast);
    this.toastComponent.toasts.push(toast);
    setTimeout(() => {
      this.hideToast(toast.id);
    }, 10000);
    return this.toastComponent.toasts.length - 1;
  }

  hideToast(id: number): void {
    this.toastComponent.toasts = this.toastComponent.toasts.filter(toast => toast.id !== id);
  }

  setToastData(toast: Toast): void {
    toast.id = toast.id || this.toastComponent.toasts?.length;
    toast.type = toast.type || 'information';
    toast.icon = toast.icon || this.iconMap[toast.type];
  }
}
