<form [formGroup]="editForm">
    <mat-form-field appearance="outline" class="w-100 mt-2 filter">
        <mat-label *ngIf="input.title && !input.noAutoCompleteTitle">{{input.title}}</mat-label>
        <mat-icon *ngIf="input.icon" matPrefix>{{input.icon}}</mat-icon>
        <input matInput placeholder="{{input.placeholder}}" formControlName="input" [required]="input.required"
            [matAutocomplete]="auto" (focus)="getData()" />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getDisplayName"
            (optionSelected)="onSelectionChange($event)" (opened)="autocompleteScroll()">
            <mat-option *ngFor="let option of options" [value]="option">
                {{option[input.nameField] || option.name || option}}
            </mat-option>
        </mat-autocomplete>
        <mat-icon *ngIf="input.suffixIcon" matSuffix class="mat-custom-arrow">{{input.suffixIcon}}</mat-icon>
    </mat-form-field>

    <div class="tags">
        <div *ngFor="let item of selectedItems" class="tag d-flex h-100 align-items-baseline mt-2">
            <span class="body black">{{item[input.nameField] || item.name || item}}</span>
            <mat-icon (click)="removeRole(item)">cancel</mat-icon>
        </div>
    </div>

</form>
