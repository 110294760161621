<div class="con">
    <div class="search-hold">
        <mat-form-field class="search" appearance="outline">
            <input type="text" placeholder="Search" matInput [formControl]="searchControl" autocomplete="off">
            <mat-icon matPrefix class="search-icon">search</mat-icon>
        </mat-form-field>
    </div>
    <div class="list-con">
        <div *ngFor="let item of list.slice(0,numVisible)" class="list-item" (click)="onItemSelected(item)"
         [matTooltipDisabled]="!(item.value?.length >= tooltipVisibleLength && enableTooltip)" [matTooltip]="item.value" matTooltipPosition="above">
            <div class="caption black nowrap">{{item.value}}</div>
            <mat-icon [ngStyle]="{'color':item.selected?'#0ac5a1':'#d8dae3'}">
                {{item.selected?'check':'add'}}</mat-icon>
        </div>
    </div>
    <div *ngIf="showClearAll" class="bottom-con">
        <button mat-stroked-button class="tertiary-button" (click)="clearAll()">Clear All</button>
    </div>
</div>
