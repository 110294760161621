import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {

  constructor() {  
  }

  public menuItemAlias = new Subject();
  public onLogoutEmitter = new Subject();
  public onBoardingCompleted = new Subject<boolean>();
  public toggleMenu = new Subject<object>();
  public refreshHelpCenter = new Subject();

  public getMenuItemAlias(): Subject<any> {
    return this.menuItemAlias;
  }

  public setMenuItemAlias(menuItem?: any) {
    this.menuItemAlias.next(menuItem);
  }

  public getLogoutEmitter(): Subject<any> {
    return this.onLogoutEmitter;
  }

  public setLogoutEmitter() {
    this.onLogoutEmitter.next();
  }
  
  public getOnBoardingStatus(): Subject<boolean> {
    return this.onBoardingCompleted;
  }

  public setOnBoardingStatus(onBoarded: boolean) {
    localStorage.setItem('onboardingComplete', JSON.stringify(onBoarded));
    this.onBoardingCompleted.next(onBoarded);
  }

  public getToggleMenu(): Subject<object> {
    return this.toggleMenu;
  }

  public setToggleMenu(obj: object) {
    this.toggleMenu.next(obj);
  }

  public getRefreshHelpCenter(): Subject<any> {
    return this.refreshHelpCenter;
  }

  public setRefreshHelpCenter() {
    this.refreshHelpCenter.next();
  }
}
