<div class="con">
    <img *ngIf="isLoader" src="{{icon}}" />
    <app-svg-icon *ngIf="!isLoader" fill={{getIconConfig(icon)?.color}} src={{getIconConfig(icon)?.name}}
        [width]="getIconConfig(icon)?.size || '64px'" [height]="getIconConfig(icon)?.size || '64px'"
        [viewBox]="getIconConfig(icon)?.viewBox"></app-svg-icon>
    <div *ngIf="title" class="title h3 black"><span *ngIf="data.subTitle" class="title-prefix"> {{ data.subTitle
            }}</span>{{title}}</div>
    <input matInput placeholder="{{inputPlaceholder}}" *ngIf="inputPlaceholder?true:false" class="input"
        [(ngModel)]="inputText" autocomplete="off" />
    <textarea matInput placeholder="{{input2Placeholder}}" *ngIf="input2Placeholder?true:false" class="input"
        [(ngModel)]="input2Text" autocomplete="off" maxlength="500" [ngClass]="{'error': input2ErrorToggle}"></textarea>
    <div (click)="messageInnerHTML && handleClick($event)" *ngIf="messageInnerHTML || message" class="message gray"
        [innerHTML]="message ?? messageInnerHTML"></div>
    <div *ngIf="data.skillItems" class="skill-item-con" style="margin-top:12px">
        <div *ngFor="let item of data.skillItems" class="skill-item caption black">
            {{item.name}}
        </div>
    </div>
    <div *ngIf="data.incompleteSkills" class="incomplete-skills-con">
        <img src="assets/icons/alert-icon.png" />
        <div class="caption white">
            Following skills were not saved because they were missing required information.
            <div class="skill-item-con" style="margin:8px 0">
                <div *ngFor="let item of data.incompleteSkills" class="skill-item caption black">
                    {{item.name}}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="data.criticalError" class="critical-error caption warn">{{data.criticalError}}</div>
    <div *ngIf="data.message2" class="message2 gray">{{data.message2}}</div>
    <mat-progress-bar id="progressBar" *ngIf="data.showProgress" mode="determinate" [value]="data.progress">
    </mat-progress-bar>
    <div class="btn-con" *ngIf="type!='wait'" [ngClass]="{'wrap-buttons':data.blockButton}">
        <button *ngIf="btn2Title" mat-stroked-button class="secondary-button"
            [ngClass]="{'white-button' : data.whiteButton , 'block-buttons': data.blockButton}"
            (click)="onBtn2Click($event)">{{btn2Title}}</button>
        <button *ngIf="btnTitle" mat-stroked-button class="primary-button"
            [ngClass]="{'warn-button' : data.warnButton, 'block-buttons': data.blockButton, 'error-button': data.errorButton, 'no-grow': data.noGrow }"
            (click)="onBtnClick($event)">
            <div class="item-center">
                <div class="icon-btn-action mr-1" *ngIf="isIconVisible(btnIconName)">
                    <app-svg-icon 
                        height="16px"
                        width="16px"
                        viewBox={{getIconConfig(btnIconName)?.viewBox}}
                        fill={{getIconConfig(btnIconName)?.color}}
                        src={{getIconConfig(btnIconName)?.name}}>
                    </app-svg-icon>
                </div>
                <div class="btn-name">{{btnTitle}}</div>
            </div>
        </button>
    </div>
</div>