<div class="rating-con body black">
    <div class="rating-title-con">
        <div class="rating-title">
            <div class="h4 black">{{title}}</div>
            <div class="caption gray">{{currentMinRating}} - {{currentMaxRating}}</div>
        </div>
        <button mat-stroked-button class="primary-button" (click)="onApply()">Apply</button>
    </div>
    <div class="rating-option-con">
        <div *ngFor="let num of ratingOptions;let last=last" class="rating-option-item"
            [ngStyle]="{'max-width':last?'20px':'auto'}">
            <div class="rating-option" matTooltip="You can select a single {{ratingAlias}} or a range">
                <div class="rating-circle" (click)="onRatingClick(c,num)"
                    [ngClass]="{'rating-circle-selected':num>=currentMinRating&&num<=currentMaxRating}">
                </div>
                <div class="caption gray" style="padding-left:5px;margin-top:4px;font-size:80%;"
                    [ngStyle]="{'color':num>=currentMinRating&&num<=currentMaxRating?'black':'#9496a5'}"
                    [ngClass]="{'fw-700':num>=currentMinRating&&num<=currentMaxRating}">
                    {{num}}
                </div>
                <div class="rating-line"
                    [ngClass]="{'rating-line-selected':num>=currentMinRating&&num<currentMaxRating}"
                    *ngIf="num<maxRating"></div>
            </div>
            <div class="rating-option" *ngIf="num<maxRating" matTooltip="You can select a single {{ratingAlias}} or a range">
                <div class="rating-circle-small" (click)="onRatingClick(c,num+ratingStep)"
                    [ngClass]="{'rating-circle-selected':num+ratingStep>=currentMinRating&&num+ratingStep<=currentMaxRating}">
                </div>
                <div class="caption gray">&nbsp;</div>
                <div class="rating-line rating-line-small"
                    [ngClass]="{'rating-line-selected':num+ratingStep>=currentMinRating&&num+ratingStep<currentMaxRating}"
                    *ngIf="num+ratingStep<maxRating"></div>
            </div>
        </div>
    </div>
</div>