import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SearchDropdownComponent } from 'src/app/modules/common-components/search-dropdown/search-dropdown.component';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { DATE_FORMATS } from '../../company-info/company-info.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { SharedDialogComponent } from 'src/app/modules/common-components/shared-dialog/shared-dialog.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-notification-trigger-dd',
  templateUrl: './notification-trigger-dd.component.html',
  styleUrls: ['./notification-trigger-dd.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ]
})
export class NotificationTriggerDdComponent implements OnInit {
  constructor(private fb: FormBuilder, private dialog: MatDialog, private ds: DataService, private weds: WaitErrorDialogsService) { }
  @Input() notificationData: any;
  @Input() config: any;
  @Output() onEvent = new EventEmitter();
  @ViewChild('ddFilters') filterDD: SearchDropdownComponent
  editForm: FormGroup;
  canEdit: true;
  userGroupFilters: any = {};
  filters: any = [];
  totalUsersCnt: number = 0;
  loadingFilter: boolean = false
  filterDropDownData: any = {
    data: [],
    disable: false,
    dontShowAll: true,
    maxItems: 10,
    mode: 'auto',
    noSort: true,
    selectedItems: [],
    title: 'Add Filter'
  }

  ngOnInit(): void {
    this.totalUsersCnt = this.config?.dataFromParent ? this.notificationData.usersCount : 0;
    this.editForm = this.fb.group({});
    this.editForm.addControl('groupName', new FormControl({ value: '', disabled: false }));
    this.editForm.addControl('description', new FormControl({ value: '', disabled: false }));
    this.getFilterData()
  }

  getFilterData() {
    this.loadingFilter = true
    const waitDialog = this.weds.showDialog({type:'wait', code: -2, dontCloseAllDialogs: true });
    let o = this.ds.saveData('admin/getUserGroupFilters', { clientId: this.ds.currentAdminClientId }).subscribe((rs: any) => {
      this.loadingFilter = false
      this.weds.closeDialog(waitDialog);
      o.unsubscribe();
      if (rs.status == 'Success') {
        this.processFilterData(rs.filters)
      } else {
        this.weds.showDialog({ type: 'generic', code: rs.error ? rs.error.code : 99 });
      }
    }, (err) => {
      this.loadingFilter = false
      this.weds.closeDialog(waitDialog);
      this.weds.showDialog({ type: 'generic', code: 0 });
      o.unsubscribe();
    });
  }

  processFilterData(filters: any) {
    filters.map((f: any, index: number) => {
      let m = { id: index, name: f.field, search: f.field, value: f.field, ...f }
      this.filterDropDownData.data.push(m)
    })
    if (this.notificationData.userGroupId) this.processData()
  }

  processData() {
    this.notificationData.filters.map((f) => {
      let m = this.filterDropDownData.data.filter(s => s.dataKey == f.dataKey)[0]
      m.selected = true
      this.filterDropDownData.selectedItems.push(m)
    })
    this.filterDD.calculateTitle()
    this.filterDD.change.emit({ type: 'select', selectedItems: this.filterDropDownData.selectedItems });
    this.editForm.get('groupName').setValue(this.notificationData.name)
    this.editForm.get('description').setValue(this.notificationData.description)
  }

  onFilterChange(event: any) {
    let oldFilters = this.filters
    this.filters = []
    event.selectedItems.map(i => {
      let o = oldFilters.find(f => f.dataKey == i.dataKey)
      if (o) this.filters.push(o)
      else {
        let savedFilter;
        if (this.notificationData.userGroupId && this.notificationData.filters.length) {
          savedFilter = this.notificationData.filters.find(f => f.dataKey == i.dataKey)
        }
        if (i.searchable) { i.searchOpts = { left: false, right: false, searchEndpoint: 'admin/' + i.endpoint, placeholder: 'Search by name, employee ID or email', clientId: this.ds.currentAdminClientId, clearOnSelect: true, }; i.selectedValues = [] }
        if (i.dataType == 'Text' && i.searchable) { i.type = 'search'; i.selectedValues = savedFilter && savedFilter.selectedValues ? savedFilter.selectedValues : [] }
        else if (i.dataType == 'Date') { i.type = 'date'; i.minDate = savedFilter && savedFilter.min ? moment(savedFilter.min) : '', i.maxDate = savedFilter && savedFilter.max ? moment(savedFilter.max) : '' }
        else if (i.dataType == 'Number') { i.type = 'number'; i.min = savedFilter && savedFilter.min ? savedFilter.min : 0, i.max = savedFilter && savedFilter.max ? savedFilter.max : this.ds.experienceLimit.max }
        else { i.type = 'select'; i.selectedValues = savedFilter && savedFilter.selectedValues ? savedFilter.selectedValues : [] }
        this.filters.push(i)
      }
    })
    if(!this.config?.dataFromParent) {
      this.onFilterValueChange()
    };
  }


  onFilterValueChange() {
    let d = this.processFiltersPayload()
    if (!d.length) return
    this.ds.saveData('admin/getUsersCountForUserGroupFilters', { clientId: this.ds.currentAdminClientId, filters: d }).pipe(take(1)).subscribe((rs: any) => {
      if (rs.status == 'Success') {
        this.totalUsersCnt = rs.usersCount
      } else {
        this.showDialog({ type: 'generic', code: rs.error ? rs.error.code : 99 });
      }
    });
  }

  onSearchResult(event: any, filter: any) {
    if (filter.selectedValues.filter(a => {
      if (filter.dataKey == 'designation') { return a.designationId == event.value.designationId; }
      else return a.userId == event.value.userId;
    }).length) return;
    filter.selectedValues.push(event.value)
    this.onFilterValueChange()
  }


  removeFilter(filter: any) {
    filter.selected = false
    let inx = this.filters.findIndex(n => n.dataKey == filter.dataKey)
    this.filters.splice(inx, 1)
    this.filterDropDownData.selectedItems.splice(inx, 1)
    this.filterDD.calculateTitle()
    if (this.filters.length) this.onFilterValueChange()
    else this.totalUsersCnt = 0
  }

  removeFilterValue(filter: any, value: any) {
    let inx = filter.selectedValues.findIndex(n => {
      if (filter.dataKey == 'designation') { return n.designationId == value.designationId; }
      else return n.userId == value.userId;
    });
    filter.selectedValues.splice(inx, 1);
    this.onFilterValueChange();
  }

  processFiltersPayload(showError: boolean = false) {
    let pFilters = []
    for (let index = 0; index < this.filters.length; index++) {
      let f = this.filters[index];
      if (f.dataType == 'Date') {
        if (f.minDate && f.maxDate) {
          f.minDate = f.minDate._isAMomentObject ? f.minDate : moment(f.minDate, 'DD MMM YYYY');
          f.maxDate = f.maxDate._isAMomentObject ? f.maxDate : moment(f.maxDate, 'DD MMM YYYY');
          if (f.minDate.valueOf() > f.maxDate.valueOf()) {
            this.showDialog({ type: 'generic', code: 129 });
            pFilters = []
            break;
          }
          let p = { dataKey: f.dataKey, min: f.minDate, max: f.maxDate }
          pFilters.push(p)
        } else {
          if (showError) {
            this.showDialog({ type: 'generic', title: 'Invalid ' + f.name, message: 'Please enter start date and end date' })
            pFilters = []
            break;
          }
        }
      }
      else if (f.dataType == 'Number') {
        if (f.min >= 0 && f.max <= this.ds.experienceLimit.max) {
          let p = { dataKey: f.dataKey, min: f.min, max: f.max }
          pFilters.push(p)
        } else {
          if (showError) {
            this.showDialog({ type: 'generic', title: 'Invalid ' + f.name, message: 'Please enter proper data' })
            pFilters = []
            break;
          }
        }
      }
      else {
        if (f.selectedValues && f.selectedValues.length) {
          let p = {
            dataKey: f.dataKey,
            selectedValues: f.selectedValues.map(s => {
              if (f.searchable) {
                if (f.dataKey == 'designation') { return s.designationId }
                else return s.userId
              } else {
                return s
              }
            })
          }
          pFilters.push(p)
        } else {
          if (showError) {
            this.showDialog({ type: 'generic', title: 'Invalid ' + f.name, message: 'No values selected' })
            pFilters = []
            break;
          }
        }
      }
    }
    return pFilters
  }

  onSave(deleteUserGroup?) {
    let d = this.processFiltersPayload(true)
    if (!d.length || this.editForm.invalid) return
    const data = { name: this.editForm.controls['groupName'].value, description: this.editForm.controls['description'].value, filters: d, userGroupId: this.notificationData.userGroupId ? this.notificationData.userGroupId : 'ADD', delete: deleteUserGroup }
    let endpoint = '';
    if (this.config?.saveApi) {
      endpoint = this.config?.saveApi;
    } else {
      endpoint = deleteUserGroup
        ? 'admin/deleteUserGroupFilters'
        : this.notificationData?.userGroupId
        ? 'admin/updateNotificationsUserGroupFilters'
        : 'admin/createNotificationsUserGroupFilters';
    }
    this.ds.saveData(endpoint, { clientId: this.ds.currentAdminClientId, data }).pipe(take(1)).subscribe((rs: any) => {
      if (rs.status == 'Success') {
        this.onEvent.emit({ type: 'REFRESH' })
      } else {
        this.showDialog({ type: 'generic', code: rs.error ? rs.error.code : 99 });
      }
    });
  }

  // This is because the dialog will not close all dialogs opened
  showDialog(data: any) {
    let dialogRef = this.dialog.open(SharedDialogComponent, {
      panelClass: 'shared-dialog',
    });
    dialogRef.componentInstance.data = data;
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      if (data.event = 'BTN_CLICK') dialogRef.close()
    })
  }

  onSeachEnter() { }
}
