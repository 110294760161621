import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ToastService } from '../modules/common-components/toast/toast.service';
import { AbstractControl } from '@angular/forms';
import * as _ from 'lodash';
import { API_ENDPOINT } from '../constants/api-endpoint.constants';
import { ACCESS_LEVELS } from '../constants';
import { ISkillItem } from '../modules/admin/admin-goal-management/components/add-goal';
import { IUserSkillItem } from '../modules/my-skills/my-skills-v2/my-skill.model';
import { StaffingRoleModalComponent } from '../modules/base/staffing-role-modal/staffing-role-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(
    private toast: ToastService,
    private dialog: MatDialog,
    private ds: DataService,
  ) { }

  // keyToMatch is optional argument to match specific key element with the arrays
  public checkEqualArray(arr1, arr2, keyToMatch = null) {
    if (!arr1.length && !arr2.length)
      return true;
    else if (arr1.length !== arr2.length)
      return false;
    return arr1.every(value1 => {
      return arr2.length && arr2.every(value2 => {
        const isObject = typeof value1 === 'object' && typeof value2 === 'object' && !Array.isArray(value1) && !Array.isArray(value2);
        if (isObject && value1[keyToMatch] === value2[keyToMatch])
          return this.deepEqual(value1, value2);
        else if (!isObject)
          return value1?.length === value2?.length && value1?.every(val => value2?.includes(val));
        else
          return true;
      });
    });
  }

  //To check the equality of JSON/Object within multiple levels
  public deepEqual(x, y) {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
      ok(x).length === ok(y).length &&
      ok(x).every(key => this.deepEqual(x[key], y[key]))
    ) : (x === y);
  }

  //To avoid pushing duplicate values to array or set (This will even consider duplicates in objects/json within multiple levels)
  public pushUniqueValue(existingDS: Array<any> | Set<any>, newItem: any): Array<any> | Set<any> {
    let valueExist: boolean = false;
    for (let item of existingDS) {
      if (this.deepEqual(item, newItem)) {
        valueExist = true;
        break;
      }
    }
    if (!valueExist) {
      if (Array.isArray(existingDS)) {
        existingDS.push(newItem)
      } else {
        existingDS.add(newItem);
      }
    }

    return existingDS;
  }

  // To display dates in different formats in UI (eg: DD-MM-YYY)
  //formatDate(new Date(), 'DD MMM YYYY [at] hh:mm A') gives 28 Mar 2023 at 04:20 PM
  //Documentation => https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
  public formatDate(date: any, format: string) {
    return date ? moment(date).format(format) : '';
  }

  // set pagination options of main search bar in tabs in skill groups section to
  // to avoid extra req. calls to BE
  public setSearchBarPaginationOpts(opts: any, searchOpts: any, offset: number = 0) {
    searchOpts.paginationStr = opts.paginationStr || '';
    searchOpts.rightDisabled = Number(opts.cnt) === Number(opts.curCnt);
    searchOpts.leftDisabled = offset === 0;
  }

  //To return short name for users eg: if name is "Rashi Mehta" then "RM" will be returned
  public getShortName(name) {
    if (!name) {
      return '';
    }
    const nameArray = name.split(' ');
    if (nameArray.length === 1) {
      return (nameArray[0].substring(0, 2)).toUpperCase();
    }
    return (nameArray[0].substring(0, 1) + nameArray[1].substring(0, 1)).toUpperCase();
  }

  public downloadFile(href: string, name: string = '') {
    let link = document.createElement('a');
    link.href = href;
    link.download = name;
    link.click();
  }

  public isValidFileFormat(fileName: string, validFormat: string) {
    //eg: fileName -> 'document.docx', validFormat -> '.doc, .docx'
    const extension = fileName.substring(fileName.lastIndexOf('.'));
    const validFormatArray = validFormat.split(',').map((element) => element.trim());
    if (!validFormatArray.includes(extension)) {
      this.toast.showToast({
        'type': 'error', 'msg': 'Invalid file format.',
        'desc': `Only ${validFormat} files are allowed`
      });
      return false;
    }
    return true;
  }

  public toAlphaNumeric(str) {
    return str.toLowerCase().replace(/[^a-z0-9]+/gi, "");
  }

  public setHasObject<T>(set: Set<T>, selectedItem: T) {
    for (const item of set) {
      if (this.deepEqual(item, selectedItem)) {
        return true;
      }
    }
    return false;
  }

  public getANotB<T>(existingDS: Set<T>, possibleValues: Set<T>): Array<T> {
    const aNotB = [];
    existingDS.forEach(selectedItem => {
      const isPresent = this.setHasObject(possibleValues, selectedItem);
      if (!isPresent) {
        aNotB.push(selectedItem);
      }
    });
    return aNotB;
  }
  public getAAndB<T>(existingDS: Set<T>, possibleValues: Set<T>): Array<T> {
    const aAndB = [];
    existingDS.forEach(selectedItem => {
      const isPresent = this.setHasObject(possibleValues, selectedItem);
      if (isPresent) {
        aAndB.push(selectedItem);
      }
    });
    return aAndB;
  }

  public isMobileDevice(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
  }

  //To sort an array of objects based on a specific field on the object
  public sortBasedOnField(arr: Array<unknown>, field: string) {
    arr.sort((a, b) => {
      if (a[field] < b[field]) {
        return -1;
      }
      if (a[field] > b[field]) {
        return 1;
      }
      return 0;
    });
  }

  public constructCsvRowsForTemplates(fileName: string, csvHeaders: Array<any>) {
    let csvRowString = '';
    for (let i = 0; i < csvHeaders.length; i++) {
      csvRowString += (i ? ',' : '') + csvHeaders[i].key;
    }
    csvRowString += '\r\n';
    for (let i = 0; i < csvHeaders.length; i++) {
      if (!csvHeaders[i].key) continue;
      csvRowString += (i ? ',' : '') + (csvHeaders[i].required ? 'Mandatory' : 'Optional');
    }
    let a = document.createElement("a");
    a.href = 'data:text/csv;base64,' + window.btoa(csvRowString);
    document.body.appendChild(a);
    a.download = fileName;
    a.click();
    document.body.removeChild(a);
  }

  public checkEmailDomain(domains) {
    return function (c: AbstractControl) {
      const value = c?.value;
      if (!value) return null;
      const domain = value.split('@')[1];
      if (!domain) return null;

      if (domains.indexOf(domain) === -1) {
        return {
          invalidDomain: true,
        }
      }
      return null;
    }
  }

  public encodeToBase64 = (toBeEncodedString: string) => btoa(toBeEncodedString);
  public decodeFromBase64 = (toBeDecodedString: string) => atob(toBeDecodedString);

  public utf8ToBase64 = (utf8String: string) => {
    const uint8Array = new TextEncoder().encode(utf8String);
    return btoa(uint8Array.reduce((acc, byte) => acc + String.fromCharCode(byte), ''));
  }

  public base64ToUtf8 = (base64String: string) => {
    const uint8Array = new Uint8Array([...atob(base64String)].map((char) => char.charCodeAt(0)));
    return new TextDecoder().decode(uint8Array);
  }

  public isValidUrl(str): boolean {
    const pattern = new RegExp(
      '^([a-zA-Z]+:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return pattern.test(str);
  }

  openUrlInNewTab(url) {
    window.open(url, '_blank');
  }

  public getUserBGColorMap(data, key) {
    const userBGColorMap = {};
    data.forEach(item => {
      const id = _.get(item, key)
      if (!userBGColorMap[id]) {
        userBGColorMap[id] = `rgb(${150 + Math.random() * 100}, ${150 + Math.random() * 100}, ${150 + Math.random() * 100})`;
      }
    })
    return userBGColorMap;
  }

  public filterKeysFromObj =
    (whitelistedFields = []) =>
      (sourceObj, addBlankStringIfNotPresent = false) =>
        whitelistedFields.reduce(
          (acc, el) => ({
            ...acc,
            ...(addBlankStringIfNotPresent
              ? {
                [el]: sourceObj[el] || ""
              }
              : {
                [el]: sourceObj[el]
              })
          }),
          {}
        );

  public filterOutKeysFromObj = (fieldsToRemove) => (sourceObj) =>
    Object.keys(sourceObj)
      .filter((key) => !fieldsToRemove.includes(key))
      .reduce(
        (acc, el) => ({
          ...acc,
          [el]: sourceObj[el]
        }),
        {}
      );

  public compareKeys(v1: any, v2: any) {
    return (v1 || {}).key === (v2 || {}).key;
  }

  public getResumeParsingApiEndpoint(){
    const treatmentMap = JSON.parse(localStorage.getItem("treatmentMap"));
    const { GET_SKILLS_FROM_RESUME, GET_MY_SKILLS } = API_ENDPOINT;
    return treatmentMap?.getSkillsFromResume === 'T1' ? GET_SKILLS_FROM_RESUME: GET_MY_SKILLS;
  }

  public convertObjectToArray(data) {
    return Object.keys(data).map(key => ({
      id: key,
      ...data[key]
    }));
  }

  public checkAccess(userRole: string = ACCESS_LEVELS.NO_ACCESS, clientFeature: string = ACCESS_LEVELS.NO_ACCESS): boolean {
    return userRole !== 'No Access' && clientFeature !== 'No Access';
  }

  public hasScrollReachedThreshold(scrollElement: any, threshold: number = 0.8, direction: 'vertical' | 'horizontal' = 'vertical'): boolean {
    const isVertical = direction === 'vertical';
    const scrollPosition = isVertical ? scrollElement.scrollTop + scrollElement.clientHeight : scrollElement.scrollLeft + scrollElement.clientWidth;
    const maxScroll = isVertical ? scrollElement.scrollHeight : scrollElement.scrollWidth;

    return scrollPosition / maxScroll > threshold;
  }

  public filterDuplicateSkills(skillItems: IUserSkillItem[] = [], userSkillItems: IUserSkillItem[] = []): IUserSkillItem[] {
    const skillItemIds = skillItems.map(item => item.skillItemId);
    return (userSkillItems).filter(item => !skillItemIds.includes(item.skillItemId));
  }

  public toCamelCase(input: string): string {
    return input
      .toLowerCase()                      
      .split(' ')                         
      .map((word, index) => {
        if (index === 0) {
          return word;                     
        }
        return word.charAt(0).toUpperCase() + word.slice(1); 
      })
      .join('');                          
  }
  
  public isFileSizeExceedingMaxLimit(files: Array<File>, maxFileSizeInMB: number): boolean {
    return files.some(file => file.size > maxFileSizeInMB * 1024 * 1024)
 }

 public showStaffingRoleModal() {
    const dialogWidth = window.innerWidth < 900 ? '90vw' : '600px';
    const dialogHeight = window.innerWidth < 900 ? '90vh' : '640px';
    
    let dialogRef = this.dialog.open(StaffingRoleModalComponent, { width: dialogWidth, height: dialogHeight, panelClass: 'profile-modal', disableClose: true });
    dialogRef.componentInstance.data = {
      selectedStaffingRole: this.ds.user?.staffingRole || [],
      dontCloseProgrammatically: true,
    }
    const onEvent = dialogRef.componentInstance.onEvent.subscribe(() => {
      dialogRef.close();
    });
    dialogRef.afterClosed().subscribe(() => { onEvent.unsubscribe(); });
  }

  public preventCharacters(event: KeyboardEvent, charactersToPrevent: string[]) {
    if (charactersToPrevent.includes(event.key)) {
      event.preventDefault();
    }
  }
  
}
