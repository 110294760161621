<div class="goal-check-in-main-con" [formGroup]="checkInForm">
  <div class="goal-check-in-header d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center gap-1">
      <mat-icon class="goal-checkin-icon fs-16 white">pin_drop</mat-icon>
      <div class="h4 white goal-checkin-title">Check in: {{data.developmentPlanDetails?.name}}</div>
    </div>
    <mat-icon class="white cursor-pointer" mat-dialog-close>close</mat-icon>
  </div>
  <div class="p-20">
    <div class="mb-20 d-flex align-items-center gap-20">
      <div class="flex-1">
        <div class="body fw-500 mb-1">Goal Progress</div>

        <!-- Incomplete and Complete -->
        <div *ngIf="!['role', 'specialization', 'certification'].includes(data.type?.toLowerCase())" class="goal-check-in-status-con py-1 px-12 border-radius-4 w-full">
          <div class="d-flex align-items-center gap-1">
            <div *ngFor="let status of ['INCOMPLETE', 'COMPLETED']"
                 class="py-1 px-3 flex-1 item-center"
                 [ngClass]="{ 'complete-incomplete-con': selectedStatus === status }"
                 (click)="selectStatus(status)">
              <span class="body fw-400">{{ status }}</span>
            </div>
          </div>
        </div>

        <!-- Percentage -->
        <div *ngIf="data.type?.toLowerCase() === 'course'" class="goal-check-in-status-con py-1 px-12 border-radius-4 w-full">
          <div class="d-flex align-items-center gap-12">
            <div (click)="data.type === 'course' ? showNumberInput() : null" class="goal-check-input">
              <span *ngIf="!showInput" class="body fw-400 gray">{{ percentageControl?.value || 0 }}%</span>
              <input *ngIf="showInput"
                     id="numberInput"
                     type="number"
                     class="complete-incomplete-con number-input"
                     [formControl]="percentageControl"
                     (blur)="hideNumberInput()"
                     (keydown.enter)="hideNumberInput()"
                     min="0" />
            </div>
            <span class="body fw-400 gray">/</span>
            <span class="body fw-400 gray">100%</span>
          </div>
        </div>

        <!-- Number -->
        <div *ngIf="data.type?.toLowerCase() === 'skill'" class="goal-check-in-status-con py-1 px-12 border-radius-4 w-full">
          <div class="d-flex align-items-center gap-12">
            <div (click)="data.type === 'custom' ? showNumberInput() : null" class="goal-check-input">
              <span *ngIf="!showInput" class="body fw-400 gray">{{ numberControl?.value || 0 }}</span>
              <input *ngIf="showInput"
                     id="numberInput"
                     type="number"
                     class="complete-incomplete-con number-input"
                     [formControl]="numberControl"
                     (blur)="hideNumberInput()"
                     (keydown.enter)="hideNumberInput()"
                     min="0" />
            </div>
            <span class="body fw-400 gray">/</span>
            <span class="body fw-400 gray">100</span>
          </div>
        </div>

        <!-- Currency (commented out in original code) -->
        <!-- <div *ngIf="data.type === 'Custom'" class="goal-check-in-status-con py-1 px-12 border-radius-4 w-full">
          <div class="d-flex align-items-center gap-12">
            <div (click)="showNumberInput()" class="goal-check-input">
              <span *ngIf="!showInput" class="body fw-400 gray">{{ currencyControl?.value || 0 }}$</span>
              <input *ngIf="showInput"
                     id="numberInput"
                     type="number"
                     class="complete-incomplete-con number-input"
                     [formControl]="currencyControl"
                     (blur)="hideNumberInput()"
                     (keydown.enter)="hideNumberInput()"
                     min="0" />
            </div>
            <span class="body fw-400 gray">/</span>
            <span class="body fw-400 gray">100$</span>
          </div>
        </div> -->

      </div>
      <div class="flex-1">
        <div class="body fw-500 mb-1">Status</div>
        <div class="goal-check-in-status-con py-2 px-12 border-radius-4 w-full"
             [ngClass]="{
               'goal-status-completed': data.progressStatus === 'COMPLETED',
               'goal-status-delayed': data.progressStatus === 'DELAYED'
             }">
          <span class="h6">{{data.progressStatus}}</span>
        </div>
      </div>
    </div>
    <div class="body fw-500 mb-1">How is this goal progressing?</div>
    <textarea class="goal-checkin-desc border-radius-4 p-2"
              placeholder="Add context... Are there any accomplishments or risks to share?"
              [formControl]="progressDescriptionControl">
    </textarea>
  </div>
  <div class="px-20 py-3 d-flex align-items-center justify-content-between">
    <button mat-stroked-button class="secondary-button" type="button" mat-dialog-close>Cancel</button>
    <button mat-stroked-button class="primary-button" type="button" (click)="onCheckInClick()">Check In</button>
  </div>
</div>
