<div class="loading-animation">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle id="dot1" cx="2" cy="10" r="2" fill="url(#paint0_linear_836_111796)" />
        <circle id="dot2" cx="10" cy="10" r="2" fill="url(#paint1_linear_836_111796)" />
        <circle id="dot3" cx="18" cy="10" r="2" fill="url(#paint2_linear_836_111796)" />
        <defs>
            <linearGradient id="paint0_linear_836_111796" x1="2" y1="8" x2="2" y2="12" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00A2ED" />
                <stop offset="1" stop-color="#1F40CB" />
            </linearGradient>
            <linearGradient id="paint1_linear_836_111796" x1="10" y1="8" x2="10" y2="12" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00A2ED" />
                <stop offset="1" stop-color="#1F40CB" />
            </linearGradient>
            <linearGradient id="paint2_linear_836_111796" x1="18" y1="8" x2="18" y2="12" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00A2ED" />
                <stop offset="1" stop-color="#1F40CB" />
            </linearGradient>
        </defs>
    </svg>
</div>