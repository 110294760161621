<div class="skill-details-wrapper">
    <div class="skill-details-header">
        Skill Details
        <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
    </div>
    <div class="skill-details-body">
        <div class="skill-details-body-inner">
            <div class="skill-details-body-inner-header">
                <div class="skill-details-body-inner-header-icon">
                    <app-svg-icon class="mt-1" src="icons/skill-icon.svg" height="20px" width="20px"
                        fill="var(--color-black-pearl)" viewBox="0 0 20 20"></app-svg-icon>
                </div>
                <span class="skill-details-body-inner-header-text">{{skillData.name}}</span>
            </div>
            <div class="skill-details-body-inner-grid">
                <div class="d-flex flex-column">
                    <span class="skill-details-body-inner-grid-header">Category</span>
                    <span class="skill-details-body-inner-grid-tag">{{skillData.skillCategory?.name}}</span>
                </div>
                <ng-container *ngFor="let hierarchy of skillData.skillHierarchy | slice:0:skillData.skillHierarchy.length - 1; let i = index">
                    <div *ngIf="!hideHierarchies[i]" class="d-flex flex-column">
                        <span class="skill-details-body-inner-grid-header">{{hierarchy.hierarchyName}}</span>
                        <span class="skill-details-body-inner-grid-text">{{hierarchy.name}}</span>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="skillData.skillDefinition" class="skill-details-body-inner-definitions">
                <div class="skill-details-body-inner-definitions-header">Definitions</div>
                <div class="skill-details-body-inner-definitions-text">
                    {{skillData.skillDefinition}}
                </div>
            </div>
            <div *ngFor="let proficiency of proficiencyLevels; let i = index"
                class="skill-details-body-inner-level-info">
                <div class="skill-details-body-inner-level-info-header d-flex justify-content-between">
                    Level {{ i + 1}} : {{proficiencyLevels[i]}}
                    <div class="skill-details-body-inner-level-info-header-star-wrapper">
                        <app-svg-icon 
                            *ngFor="let level of proficiencyLevels; let j = index" 
                            src="icons/skill-star.svg" 
                            [fill]="j <= i ? 'var(--primary-color)' : 'var(--color-gray-3)'"
                            height="20px" 
                            width="20px" 
                            viewBox="0 0 25 24"
                        ></app-svg-icon>
                    </div>
                </div>
                <div class="skill-details-body-inner-level-info-text">
                    {{ getProficiencyData(skillData.proficiencyData, i + 1) }}
                </div>
            </div>
        </div>
    </div>
</div>