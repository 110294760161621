import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IMyGrowthPlan, IMyGrowthResponse } from '.';
import { GoalStatusType } from '../../my-goals-layout';
import { GoalService } from 'src/app/services/goal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IGoalStatus } from '../my-goal-cards';

@Component({
  selector: 'app-my-growth-plans-card',
  templateUrl: './my-growth-plans-card.component.html',
  styleUrls: ['./my-growth-plans-card.component.scss']
})
export class MyGrowthPlansCardComponent implements OnChanges {

  @ViewChild('cardsWrapper') cardsWrapper!: ElementRef;

  @Input() label: string = 'My Growth Plans';
  @Input() myGrowthPlanData: IMyGrowthResponse;
  @Input() goalsCardData: IGoalStatus[] = [];
  @Output() viewAllEvent: EventEmitter<boolean> = new EventEmitter();

  public scrollLeftDisabled = true;
  public scrollRightDisabled = false;

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

    constructor(public goalService: GoalService, private router: Router, private route: ActivatedRoute) { }

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.myGrowthPlanData && this.myGrowthPlanData?.data?.length) {
      setTimeout(() => {
        if (this.cardsWrapper) {
          this.updateButtonStates();
          this.cardsWrapper.nativeElement.addEventListener('scroll', () => this.updateButtonStates());
        }
      }, 0);
    }
  }

  //------------------------------------------------------------------
  // Event Handlers
  //------------------------------------------------------------------

  scrollLeft() {
    this.cardsWrapper.nativeElement.scrollBy({ left: -570, behavior: 'smooth' });
  }

  scrollRight() {
    this.cardsWrapper.nativeElement.scrollBy({ left: 570, behavior: 'smooth' });
  }

  getColor(status: string) {
    let color = null;
    switch(status){
      case 'inReview':  color = this.goalService.getColor(GoalStatusType.PRIMARY); break;
      case 'completed': color = this.goalService.getColor(GoalStatusType.SUCCESS); break;
      case 'delayed':   color = this.goalService.getColor(GoalStatusType.DANGER); break;
    }
    return color;
  }


  onViewAll() {
    this.viewAllEvent.emit();
  }

  navigateToGrowthPlan(myGrowthPlanObj: IMyGrowthPlan) {
    const queryParams = {
      developmentPlanId: myGrowthPlanObj.developmentPlanId
    };
    this.router.navigate(["growthPlan"],  {relativeTo: this.route, queryParams});
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private updateButtonStates() {
    if (this.cardsWrapper) {
      const { scrollLeft, scrollWidth, clientWidth } = this.cardsWrapper.nativeElement;
      this.scrollLeftDisabled = scrollLeft <= 0;

      this.scrollRightDisabled = scrollLeft + clientWidth >= scrollWidth - 1;
    }
  }

}

