import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Validators
export function emptySpaceOnly(control: AbstractControl) : ValidationErrors | null {
    if (typeof control.value === 'string' && (!control.value || !control.value.trim())) {
        return { emptySpaceOnly: true };
    }
    return null;
}

export function ValidateEmail(control: AbstractControl): { [key: string]: any } | null {
    let g = control.value;
    if (!g) return { error: 'Invalid email address' };
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(g) ? null : { error: 'Invalid email address' };
}

export function ValidateEmails(control: AbstractControl): { [key: string]: any } | null {
    let g = control.value;
    // if (!g) return { error: 'Invalid email address' };
    let s = g.split(','), flag = true;
    s.map((email) => {
        if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email.trim())) flag = false;
    })
    return flag ? null : { error: 'Invalid email address' };
}

export function ValidatePassword(control: AbstractControl): { [key: string]: any } | null {
    let p = control.value;
    if (!p || p.length < 8) return { error: 'Password needs to have minimum 8 characters with upper case, lower case, numeric & special characters' };
    if (p.search(/[A-Z]/) < 0 || p.search(/[a-z]/) < 0 || p.search(/[0-9]/) < 0 || p.replace(/[a-z,0-9]/gi, '').length < 1) return { error: 'Password needs to have upper case, lower case, numeric & special characters' }
    return null;
}

export function ValidatePassword2(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
        return !!control.parent && !!control.parent.value && control.value === control.parent.controls[matchTo].value ? null : { error: 'Passwords need to match' };
    };
}

export function ValidateMobile(control: AbstractControl): { [key: string]: any } | null {
    let g = control.value;
    if (!g) return { mobileValid: false };
    var v = g.v || g || '';
    v = v.replace(/[^\d]+/gi, '');
    if (v.length > 10) v = v.replace(/^(91|0)/, '');
    if (v.length > 10) v = v.replace(/^(\+*91)/, '');
    if (v.length < 8 || v.length > 12) return { mobileValid: false };
    return null;
}

export function ValidateHexColor(control: AbstractControl): { [key: string]: any } | null {
    const hexColor = control.value;

    // Allow empty values
    if (!hexColor) {
        return null;
    }

    const isValidHexColor = /^#[0-9A-F]{6}$/i.test(hexColor);
    if (!isValidHexColor) {
        return { error: 'Invalid HEX color code. Must include # followed by 6 hexadecimal characters.' };
    }
    return null;
}

export function ValidateString(min: number, max: number = 0): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let s: string = control.value;
        if (!s) return null;
        s = s.trim().replace(/<\/?[^>]+>/gi, '')
        if (s.length < min) return { error: 'Minimum of ' + min + ' character' + (min == 1 ? '' : 's') + ' required' };
        if (max && s.length > max) return { error: 'Maximum of ' + max + ' character' + (max == 1 ? '' : 's') + ' allowed' };
        return null;
    }
}

export function ValidateURL(control: AbstractControl): { [key: string]: any } | null {
    if (!(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(control.value))) return { error: 'Invalid URL' };
    return null;
}

export function ValidateDomains(control: AbstractControl): { [key: string]: any } | null {
    let g = control.value;
    if (!g) return null;
    let s = g.split(','), flag = true;
    s.map((domain) => {
        if (ValidateDomainUrl(domain)) flag = false;
    })
    return flag ? null : { error: 'Invalid domain name' };
}

export function ValidateDomainUrl(value: String): boolean {
    const isValid = !/^[a-z]+[0-9,\-]*[a-z]+\.{1,1}[a-z]{2,}$/i.test(value.trim())
    return isValid;
}