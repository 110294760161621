import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {ComponentCanDeactivate} from './component-can-deactivate';
import { SharedDialogComponent } from '../common-components/shared-dialog/shared-dialog.component';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Observer } from 'rxjs';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private weds: WaitErrorDialogsService, private dialog: MatDialog) { }

  canDeactivate(component: ComponentCanDeactivate): boolean {
    if(component.canDeactivate()) {
      return true;
    }
      
    return Observable.create((observer: Observer<boolean>) => {
      // UnsavedChangesDialog defined somewhere else and imported above
      let dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: false });
      dialogRef.componentInstance.data = { type: 'generic', title: 'Confirm Navigation', message: 'You have unsaved changes. Are you sure you want to leave this page?', btnTitle: 'Yes', btn2Title: 'Cancel' };

      dialogRef.componentInstance.onEvent.subscribe((data: any) => {
        if (data.event == 'BTN_CLICK') {
          return dialogRef.close(true);
        }
        dialogRef.close(false);
      });
      
      dialogRef.afterClosed().subscribe(result => {
          observer.next(result);
          observer.complete();
        }, (error) => {
          observer.next(false);
          observer.complete();
      });
    });
  }
}
