<div class="search-dialog d-flex align-items-center flex-row p-0">
    <mat-form-field appearance="none" class="left">
        <mat-select #matSelect [(ngModel)]="selectedOption" class="gray" (selectionChange)="onFilterSelection()">
            <mat-option *ngFor="let option of filterOptions" [value]="option?.key" class="caption">{{option?.name | titlecase}}</mat-option>
        </mat-select>
        <mat-icon matSuffix class="mat-custom-arrow gray">keyboard_arrow_down</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="none" class="right flex-fill">
        <input matInput #searchInput placeholder="Search..." class="w-90 py-12 d-flex" [matAutocomplete]="auto" type="text" [(ngModel)]="searchText" (input)="onSearchInput()" >
        <mat-autocomplete #auto="matAutocomplete" class="global-search-max-height">
            <mat-option  class="option-con" *ngFor="let option of searchResults" [value]="option.name" disabled="true">
                <ng-container *ngIf="option" class="d-flex h-100">
                    <div class="d-flex align-items-center justify-content-between gap-4">
                        <div class="d-flex flex-column flex-fill align-self-stretch align-items-start justify-content-center nowrap">
                            <label matTooltip="{{ option.name || option.title}}" class="option-ellipsis h-24px body black fw-500">{{ option.name || option.title}}</label>
                            <label class="h-24px caption gray">in {{ option['Skill Category'] || option?.skillCategoryTitle || option?.type | titlecase}}</label>
                        </div>

                        <button mat-button [ngClass]="option.isAdded ? 'secondary-button primary' : 'primary-button white' " class="" (click)="onAddItemClick(option)" >
                            <div class="d-flex label fw-500 align-items-center justify-content-center flex-row">
                                <mat-icon matPrefix [hidden]="option.isAdded" class="fs-16 mr-0 d-flex align-items-center">add</mat-icon>
                                <span>{{ option.isAdded ? 'Added' : 'Add'}}</span>
                            </div>
                        </button>
                    </div>
                </ng-container>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>