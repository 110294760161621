<div class="multilevel-dropdown-container" [style.min-width.px]="minWidth">
  <!-- Selected option display -->
  <div class="selected-option" (click)="toggleDropdown()">
    {{ selectedOption?.label || 'Select an option' }}
  </div>

  <!-- Main Dropdown -->
  <div
    *ngIf="dropdownVisible"
    class="dropdown"
    [ngClass]="dropdownPosition"
    (mouseleave)="closeDropdown()"
    (mouseenter)="keepDropdownOpen()"
  >
    <div
      class="dropdown-item"
      *ngFor="let option of options"
      (mouseenter)="option.subOptions ? showSubMenu(option) : hideSubMenu()"
      (click)="!option.subOptions && onOptionSelect(option)"
    >
      {{ option.label }}
      <span *ngIf="option.subOptions" class="arrow-right">&#9654;</span>
    </div>

    <!-- Sub Dropdown -->
    <div
      class="sub-dropdown"
      *ngIf="subOptionsVisible && hoveredOption?.subOptions"
      [style.left.px]="subMenuLeftPosition"
      (mouseenter)="keepSubMenuOpen()"
      (mouseleave)="hideSubMenu()"
    >
      <div
        class="dropdown-item"
        *ngFor="let subOption of hoveredOption.subOptions"
        (click)="onSubOptionSelect(subOption)"
      >
        <mat-icon class="btn-icon fs-16 mt-2">{{ subOption.icon }}</mat-icon> {{ subOption.label }}
      </div>
    </div>
  </div>
</div>
