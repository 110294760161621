import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'redirect', loadChildren: () => import('./modules/redirect/redirect.module').then(m => m.RedirectModule) },
  { path: '', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule), pathMatch: 'full' },
  { path: '', loadChildren: () => import('./modules/base/base.module').then(m => m.BaseModule) },
  { path: '**', redirectTo: '' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
