<div>
  <div class="chart-body">
    <div class="skill-category" *ngFor="let skillGroup of skillGroups; index as i"
      [ngStyle]="{'--i': i, '--j': skillGroups.length}">
      <div class="skill-category-text-con">
        <div class="label primary compentency-title">{{ skillGroup }}</div>
      </div>
    </div>
    <canvas #canvas [height]="(size=='full'?width:width/2)+'px'" [width]="width" baseChart [datasets]="radarChartData.datasets"
      [labels]="radarChartData.labels" type="radar" [options]="radarChartOptions">
    </canvas>
  </div>
</div>
