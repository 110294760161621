<div class="container">
    <div>
        <label class="h4 black" *ngIf="config?.title">{{config.title}}</label>
        <p class="body gray" *ngIf="config?.subTitle">{{config.subTitle}}</p>
    </div>

    <div class="d-flex flex-column align-items-start align-self-stretch p-bottom-0">
        <div *ngFor="let item of selectedData,let i=index;" class="skill-row">
            <div class="skill-label">{{item?.displayName}}</div>
            <div class="d-flex">
                <app-single-star [key]="'value'" [client]="client" class="skill-field-star" [editable]="canEdit"
                    [skillItem]="item || {}" [value]="item?.value || 0" (onSetValue)="onSetRating($event)">
                </app-single-star>

                <mat-icon class="skill-delete-icon" (click)="deleteItem(item)"
                    matTooltip="Delete the skill">close</mat-icon>
            </div>
        </div>
    </div>

    <div class="d-flex align-self-start" *ngIf="!showTextbox">
        <button
            class="add-btn d-flex p-2 justify-content-center align-items-center gap-1 border-radius-4 h6 primary fw-500"
            (click)="showTextboxOnClick()">
            <app-svg-icon fill="var(--primary-color-light)" src="icons/add.svg"></app-svg-icon>
            Add Skill
        </button>
    </div>

    <div class="search-con">
        <form *ngIf="showTextbox">
            <mat-form-field appearance="none">
                <input class="d-flex p-2 align-items-center gap-2 align-self-stretch border-radius-4" type="text"
                    placeholder="Search skill..." aria-label="Skills" matInput #skillInput [formControl]="searchControl"
                    [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addItem($event.option.value)">
                    <mat-option class="search-result-con" *ngFor="let option of availableItemsToDisplay | async"
                        [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="searchControl.invalid && searchControl.touched && config?.required">Please provide skills associated with
                    above Certification name.</mat-error>
            </mat-form-field>
        </form>
    </div>
</div>