import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SvgCacheService {
    private cache = new Map<string, object>();
    private requestQueue = new Map<string, Promise<object>>();

    fetchSvg(src: string): Promise<object> {
        // Check if the SVG is already in cache
        if (this.cache.has(src)) {
            return Promise.resolve(this.cache.get(src)!);
        }

        // Check if there is an ongoing request for the same SVG
        if (this.requestQueue.has(src)) {
            return this.requestQueue.get(src)!;
        }

        // Create a new fetch request and add it to the queue
        const fetchPromise = fetch(`../../../assets/${src}`)
            .then(response => response.text())
            .then(svg => {
                const svgContent = svg.match(/<svg[^>]*>([\s\S]*?)<\/svg>/)[1];
                const viewBox = svg.match(/viewBox="([^"]*)"/)?.[1];
                const height = svg.match(/height="([^"]*)"/)?.[1];
                const width = svg.match(/width="([^"]*)"/)?.[1];
                const result = { svgContent, viewBox, height, width };

                // Cache the result and remove the request from the queue
                this.cache.set(src, result);
                this.requestQueue.delete(src);
                return result;
            })
            .catch(error => {
                // Remove the request from the queue if it fails
                this.requestQueue.delete(src);
                throw error;
            });

        // Add the fetch promise to the queue
        this.requestQueue.set(src, fetchPromise);
        return fetchPromise;
    }
}
