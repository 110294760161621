import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client } from 'src/app/models/client.model';
import { CommonCalculationsService } from 'src/app/services/common-calculations.service';

@Component({
  selector: 'app-star-ratings-dialog',
  templateUrl: './star-ratings-dialog.component.html',
  styleUrls: ['./star-ratings-dialog.component.scss']
})
export class StarRatingsDialogComponent implements OnInit {
  @Input() client: Client;
  @Input() skillItem: any;
  @Input() opts: any = {};//If specified, needs an array called ratings
  @Input() showRequiredProficiency: boolean = false;
  @Output() change = new EventEmitter();
  skillRatings: any[] = [];
  constructor(private cc: CommonCalculationsService) { }

  ngOnInit(): void {
    if (!this.opts.ratings) {//Default: use all ratings
      this.opts.ratings = [];
      this.skillRatings = (this.client.smsSettings.categoryWiseSettings.filter((item) => item.categoryId == this.skillItem.skillCategory.categoryId)[0] || {}).skillRatings || [];
      this.skillRatings.map((item) => {
        if (item.useRating != 'Yes') return;
        this.opts.ratings.push({ key: item.dataKey, title: item.title || item.name, editable: item.requiresApproval == 'Yes' });
      });
    this.cc.calculateOverallRating(this.skillRatings, this.skillItem);
    }
  }
  onSetValue(event, item) {
    this.skillItem[item.key] = event.data.value;
    this.cc.calculateOverallRating(this.skillRatings, this.skillItem);
    this.change.emit({ skillItem: this.skillItem, rating: item });
  }
}
