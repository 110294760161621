import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { GlobalErrorHandlerService } from '../services/global-error-handler.service';
import { WaitErrorDialogsService } from '../services/wait-error-dialogs.service';

@Injectable()
export class GeneralApiInterceptor implements HttpInterceptor {

  constructor(private router: Router, private ds: DataService, private errorHandler: GlobalErrorHandlerService, private weds: WaitErrorDialogsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe(
      map(result => {
        if (result?.['body']?.['status'] == 'Logout') {
          this.ds.onLogout();
        }
        return result;
      }),
      catchError((requestError: HttpErrorResponse) => {
        this.errorHandler.handle(requestError);
        switch (requestError?.status) {
          case 401:
          case 403:
          this.ds.onLogout();
          break;
        }
        return throwError(requestError);
      })
    );
  }
}
