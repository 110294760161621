import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SearchDdDialogComponent } from './search-dd-dialog/search-dd-dialog.component';

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent implements OnInit {
  @Input() data: any;
  @Output() change = new EventEmitter();
  @ViewChild('dropDownButton') dropDownButton: ElementRef;

  title: string;
  isDropdownOpen = false;
  hasSelectedItems = false;
  constructor(private dialog: MatDialog) { }

  
  ngOnInit(): void {
    if (!this.data.selectedItems) this.data.selectedItems = [];
    this.calculateTitle();
  }
  toggleDropdown() {
    if (!this.dropDownButton) return;
    const position = this.getPosition();
    const dialogRef = this.dialog.open(SearchDdDialogComponent, { width: '200px', height: '295px', panelClass: 'dd-modal', position, backdropClass: 'dd-modal-backdrop' } as MatDialogConfig);
    dialogRef.componentInstance.data = this.data;
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      if (data.type == 'selection') {
        this.data.selectedItems = data.selectedItems;
        this.change.emit({ type: 'select', selectedItems: data.selectedItems });
        this.calculateTitle();
      }
      if(data.event == 'CLEAR_ALL'){
        dialogRef.close()
      }
    });
    dialogRef.afterClosed().subscribe(() => { this.isDropdownOpen = false; onEvent.unsubscribe(); });
    this.isDropdownOpen = true;
  }
  calculateTitle() {
    if (!this.data?.selectedItems?.length) {
      this.title = this.data?.title + (!this.data?.dontShowAll ? ': All' : 'Select');
    } else {
      this.title = (this.data?.title ? this.data?.title + ': ' : ''); 
      this.title += this.data?.selectedItems[0]?.value + (this.data?.selectedItems?.length > 1 ? " and " + (this.data?.selectedItems?.length - 1) + " more" : "");
    }
    this.hasSelectedItems = this.data?.selectedItems?.length ? true : false;
  }
  resetFilters() {
    this.data.selectedItems = [];
    this.data.data.map((d) => {
      d.selected = false;
    });
    this.hasSelectedItems = false;
    this.calculateTitle();
  }

  getPosition() {
    const rect = this.dropDownButton?.nativeElement?.getBoundingClientRect();
    return { left: (rect.left - 200 + rect.width) + 'px', top: (rect.bottom + 4) + 'px' };
  }
}
