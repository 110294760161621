import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { THEME_KEYS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private ds: DataService) {}

  public setClientTheme(): void {
    const clientThemeVariables = this.getClientThemeVariables();
    this.applyTheme(clientThemeVariables);
  }

  public getColorValue(variableName: string): string {
    return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim() || '';
  }

  private getClientThemeVariables(): { [key: string]: string } {
    const client = this.ds.client || {};
    const themeKeys = THEME_KEYS;

    const themeVariables: { [key: string]: string } = {};

    themeKeys.forEach(key => {
      const cssVarName = `--${key.replace(/([A-Z])/g, '-$1').toLowerCase()}`;
      /* Use defaults if the variables are not changed */
      if (client[key] != null && client[key] !== '') {  
        themeVariables[cssVarName] = client[key];
      }
    });

    return themeVariables;
  }

  private applyTheme(themeVariables: { [key: string]: string }): void {
    const root = document.documentElement;
    Object.keys(themeVariables).forEach(variable => {
      root.style.setProperty(variable, themeVariables[variable]);
    });
  }
}
