import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.scss']
})
export class ProfilePicComponent implements OnInit {
  @Input() user: any;
  @Input() canEdit = false;
  @Input() fontSize;
  @Output() onProfileClick = new EventEmitter();
  @Output() openFileInput = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  onClick() {
    this.onProfileClick.emit();
  }
  onFileClick() {
    this.openFileInput.emit();
  }
}
