import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[textOverflowTooltip]',
  providers: [MatTooltip]
})
export class TextOverflowTooltipDirective {
  @Input('textOverflowTooltip') tooltipText!: string;

  constructor(private el: ElementRef,
    private tooltip: MatTooltip
  ) { }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.isTextOverflowing()) {
      const tooltipText = this.tooltipText || this.el.nativeElement.innerText || this.el.nativeElement.textContent.trim();
      this.tooltip.message = tooltipText;
      this.tooltip.position = 'above';
      this.tooltip.show();
    } else {
      this.tooltip.hide();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.tooltip.hide();
  }

  private isTextOverflowing(): boolean {
    const element = this.el.nativeElement;
    const range = document.createRange();
    range.setStart(element, 0);
    range.setEnd(element, element.childNodes.length);

    const rangeWidth = range.getBoundingClientRect().width;
    const elementWidth = element.getBoundingClientRect().width;

    return Number(rangeWidth?.toFixed(2)) > Number(elementWidth?.toFixed(2));
  }
}
