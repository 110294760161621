export enum ResponseKey {
  OK = "ok",
}

export enum PayloadKey {
  CATEGORY = "category",
  ASSIGNED_AS = "assignedAs",
  STATUS = "status",
  IS_ARCHIVED = "isArchived",
  SEARCH_KEY = "searchkey",
  PROGRESS_STATUS = "progressStatus",
}

export enum GoalAssignedAs {
  RECOMMENDED = "RECOMMENDED",
  ASSIGNED = "ASSIGNED",
}

export interface IGoalGrowthPlan {
  type: string;
  expectedCountForGoals: number;
  _id: string;
}

export interface ITimePeriod {
  startDate: string;
  endDate: string;
}

export interface IMyGrowthPlan {
  developmentPlanId: string;
  name: string;
  status: string;
  planEndDate: string;
  description: string;
  timePeriod: ITimePeriod;
  participants: IParticipant;
  clientId: string;
  isArchived: boolean;
  goals: IGoalGrowthPlan[];
  createdAt: string;
  updatedAt: string;
  goalsProgressStatusAndCount: Record<string, any>;
  daysLeft?: number;
  activePeriodStartDate: string;
  userWithoutGoals?: number;
}

export interface IGrowthPlan {
  developmentPlanId: string;
  timePeriod: ITimePeriod;
  name: string;
  description: string;
  planEndDate: string;
  goalsCount: number;
  participants: number;
  status: string;
  showAction?: boolean;
}


export interface IMyGrowthResponse {
  ok: boolean;
  data: IMyGrowthPlan[];
  previous: any;
  next: number;
  count: number;
}

export interface IParticipant {
  userGroupIds: {
    userGroupId: string;
    _id: string;
  }[];
  excludeUserIds: {
    userId: string;
    _id: string;
  }[];
}
