import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Client } from 'src/app/models/client.model';
import { CommonCalculationsService } from 'src/app/services/common-calculations.service';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StarRatingComponent implements OnInit {
  @Input() client: Client;
  @Input() key: string;
  @Input() title: string;
  @Input() editable: boolean = false;
  @Input() skillItem: any;
  @Input() noTooltip = false;
  @Input() color = 'var(--primary-color)';
  @Input() greyedOut: boolean = false;
  @Input() onEndorsementPage: boolean = false;
  @Output() onSetValue = new EventEmitter();
  toolTip = "";

  maxRating = 5;
  stars = [];
  toolTipValue = Array(this.maxRating).fill("1 *: Basic Knowledge / Classroom training\n2 *: Basic hands-on knowledge / Limited project experience\n3 *: Good knowledge of skill / Ready for client deployment with some support\n4 *: Very good knowledge of skill / Ready for client deployment without support\n5 *: Expert in skill / Can train others")
  settings: any;

  constructor(private cc: CommonCalculationsService) { }
  ngOnInit(): void {
    if (this.skillItem.skillCategory) {
      this.settings = this.client.smsSettings.categoryWiseSettings.filter((item) => item.categoryId == this.skillItem.skillCategory.categoryId)[0] || {};

    }
    if (this.client && this.client.smsSettings.skillRating == 'Stars 1-4') {
      this.maxRating = 4;
      this.toolTipValue = Array(this.maxRating).fill("1 *: Basic Knowledge / Classroom training\n2 *: Basic hands-on knowledge / Limited project experience\n3 *: Good knowledge of skill / Ready for client deployment with some support\n4 *: Expert in skill / Can train others");
    }
    if (window.location.host.search(/cts|congni|hcl/i) >= 0) this.toolTipValue = Array(this.maxRating).fill("PL1: Learner: Familiar with the skill – has shown some practical application via live projects and/or learning modules\nPL2: Practicioner: Demonstrated application of skill in live projects in supervised setting\nPL3: Specialist: Demonstrates knowledge of best practices and can handle demand delivery independently\nPL4: Expert: Seen as a coach and thought leader for the skill");
    if (this.client) {
      let keys = []
      for (let i = 0; i < this.maxRating; i++) {
        keys.push('configureSkillsTooltip' + (i + 1));
      }
      keys.map((key, i) => {
        if (this.client.smsSettings[key]) {
          this.toolTipValue[i] = this.client.smsSettings[key];
        }
      })
    }
    this.setProficiencyTooltip();
    for (let i = 0; i < this.maxRating; i++) {
      this.stars.push(i + 1);
    }
    if (this.editable) this.toolTip = (this.title || 'Overall Rating') + ': ' + (this.skillItem[this.key] || 0);
    if (this.noTooltip) this.toolTipValue = Array(this.maxRating).fill('');
  }
  
  private setProficiencyTooltip() {
    if (this.client && this.client.smsSettings.useProficiencyTooltip === 'Yes' && this.skillItem?.proficiencyData && this.skillItem.proficiencyData?.descriptionEnabled) {
      for (let i = 0; i < this.maxRating; i++) {
        let key = 'l' + (i + 1) + 'description'
        this.toolTipValue[i] = this.skillItem.proficiencyData[key] || this.toolTipValue[i];
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.skillItem && !changes.skillItem.firstChange) {
      this.setProficiencyTooltip();
    }
  }

  onStarClick(value) {
    if (!this.editable) return;
    this.skillItem[this.key] = value;
    if (this.skillItem.skillCategory && this.settings.skillRatings) {
      this.cc.calculateOverallRating(this.settings.skillRatings, this.skillItem);
    }
    this.onSetValue.emit({ event: 'onSetRating', data: { key: this.key, skillItem: this.skillItem, value: this.skillItem[this.key] || 0 } });
  }
  ceil(v) {
    return Math.ceil(v);
  }

}
