import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GoalService } from 'src/app/services/goal.service';
import { GoalStatusHeader, GoalStatusType } from '../../my-goals-layout';
import { IMyGrowthPlan } from '../my-growth-plans-card';
import { IGoalStatus } from '../my-goal-cards';

@Component({
  selector: 'app-my-growth-plan-individual-card',
  templateUrl: './my-growth-plan-individual-card.component.html',
  styleUrls: ['./my-growth-plan-individual-card.component.scss']
})
export class MyGrowthPlanIndividualCardComponent implements OnChanges {

  @Input() public myGrowthPlanObj: IMyGrowthPlan = null;
  @Input() public goalsCardData: IGoalStatus[] = [];
  @Input() public hideHeader: boolean = false;

  private readonly IN_REVIEW_STATUSES = ['REJECTED', 'PENDING', 'REVISED'];
  public GoalStatusHeader = GoalStatusHeader;
  public totalGoalsValue: number = 0;

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(public goalService: GoalService) { }

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.myGrowthPlanObj && this.myGrowthPlanObj) {
      this.myGrowthPlanObj.daysLeft = this.calculateDaysLeft(this.myGrowthPlanObj.planEndDate);

      this.IN_REVIEW_STATUSES.forEach(status => {
        if(this.myGrowthPlanObj.goalsProgressStatusAndCount[status]) {
          this.myGrowthPlanObj.goalsProgressStatusAndCount.inReview = this.myGrowthPlanObj.goalsProgressStatusAndCount[status];
        }
      })
    }
    if (changes.goalsCardData && this.goalsCardData) {
      this.calculateTotalGoalsValue();
    }
  }

  //------------------------------------------------------------------
  // Event Handlers
  //------------------------------------------------------------------

  getColor(status: string) {
    let color = null;
    switch(status){
      case 'inReview':  color = this.goalService.getColor(GoalStatusType.PRIMARY); break;
      case 'completed': color = this.goalService.getColor(GoalStatusType.SUCCESS); break;
      case 'delayed':   color = this.goalService.getColor(GoalStatusType.DANGER); break;
      case 'warning':   color = this.goalService.getColor(GoalStatusType.WARNING); break;
    }
    return color;
  }

  private calculateTotalGoalsValue(): void {
    this.totalGoalsValue = this.goalsCardData.reduce((acc, goal) => acc + goal.value, 0);
  }

  public calculateActivePhasePosition(): number {
    const startDate = new Date(this.myGrowthPlanObj.timePeriod.startDate).getTime();
    const endDate = new Date(this.myGrowthPlanObj.timePeriod.endDate).getTime();
    const activePhaseStart = new Date(this.myGrowthPlanObj.activePeriodStartDate).getTime();

    return ((activePhaseStart - startDate) / (endDate - startDate)) * 100;
  }

  public hasNextGoal(currentGoal: string): boolean {
    const statuses = ['GOAL_SET', 'inReview', 'COMPLETED', 'DELAYED'];
    const currentIndex = statuses.indexOf(currentGoal);

    for (let i = currentIndex + 1; i < statuses.length; i++) {
      const status = statuses[i];
      if (this.myGrowthPlanObj.goalsProgressStatusAndCount[status] !== null &&
          this.myGrowthPlanObj.goalsProgressStatusAndCount[status] >= 0) {
        return true;
      }
    }
    return false;
  }

  public hasValue() {
    return Object.keys(this.myGrowthPlanObj.goalsProgressStatusAndCount)?.length
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private calculateDaysLeft(planEndDate: string): number {
    const currentDate = new Date();
    const endDate = new Date(planEndDate);

    const differenceInTime = endDate.getTime() - currentDate.getTime();

    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays > 0 ? differenceInDays : 0;
  }

}
