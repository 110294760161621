<form [formGroup]="listItemsFormGroup" (ngSubmit)="save()">
    <div class="d-flex flex-column gap-3">
        <div *ngIf="input.title" class="h3 black ">{{input.title}}</div>
        <div class="d-flex flex-column gap-2">
            <div *ngIf="input.subTitle" class="h4 black">{{input.subTitle}}</div>
            <!-- Multi select drop down -->
            <mat-form-field *ngIf="input.addFromDropDown" class="drop-down font-weight-normal" appearance="outline">
                <mat-label>{{ input.dropDownPlaceholder || 'Choose from below options' }}</mat-label>
                <mat-select multiple formControlName="listItemInput"
                (selectionChange)="listItemsChangedByDropDown($event)">
                    <mat-option *ngFor="let option of input.dropDownOptions" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Drag and drop -->
            <div *ngIf="selectedItems.length" class="d-flex flex-column list-items border-radius-4" cdkDropList
                (cdkDropListDropped)="changeOrder($event)">
                <div class="list-item d-flex flex-row justify-content-between align-items-center gap-2 p-2"
                    *ngFor="let value of selectedItems; index as i;" cdkDrag [cdkDragDisabled]="!input.canOrder || input.disabled">
                    
                    <span *ngIf="!input.addFromTextBox">{{value}}</span>
                    <mat-form-field *ngIf="input.addFromTextBox" appearance="none" class="text-box admin-input-form-field">
                        <input type="text" matInput [value]="value"
                            [placeholder]="input.textBoxPlaceHolder"
                            (focusout)="onTextBoxBlur($event, i)" [disabled]="input.disabled"/>
                    </mat-form-field>
                    
                    <mat-icon *ngIf="input.canRemoveItem && !input.disabled" (click)="removeItemFromList(i)"
                        class="d-flex flex-row justify-content-center align-items-center opacity-2 opacity-hover-9 cursor-pointer
                        ">clear</mat-icon>
                </div>
            </div>

            <div *ngIf="!input.disabled" class="admin-save-button-con">
                <button mat-stroked-button *ngIf="input.addFromTextBox" (click)="addTempItemFromTextBox()" class="secondary-button" type="button">Add</button>
                <button *ngIf="input.useSaveButton" type="submit" mat-stroked-button class="primary-button">Save</button>
            </div>
        </div>
    </div>
</form>
