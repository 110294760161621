export const IDP_FILTER_KEY = {
  STATUS: "status",
  RECOMMENDED: "RECOMMENDED",
  ASSIGNED: "ASSIGNED",
  ISARCHIVED: "isArchived",
  START_DATE: "startDate",
  END_DATE: "endDate",
  TYPE: "type",
  CATEGORY: "category",
  PROGRESS_STATUS: "progressStatus",
  APPROVAL_STATUS: "approvalStatus",
}

export const IDP_GROWTH_PLAN_STATUS = {
  ACTIVE: 'ACTIVE'
}
