<form [formGroup]="editForm">
    <mat-form-field appearance="outline" class=" input-form-field">
        <mat-label *ngIf="input.title&&!input.noAutoCompleteTitle">{{input.title}}</mat-label>
        <mat-icon *ngIf="input.icon" matPrefix>{{input.icon}}</mat-icon>
        <input matInput placeholder="{{placeHolder}}" formControlName="inputControl" [required]="!input.notRequired"
            [matAutocomplete]="auto" (focus)="doInitialLoad()"/>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getDisplayName" 
            (optionSelected)="onSelectionChange($event)" (opened)="autocompleteScroll()">
            <mat-option *ngFor="let option of options" [value]="option">
                {{getDisplayOptions(option)}}
            </mat-option>
        </mat-autocomplete>
        <mat-icon *ngIf="input.suffixIcon" matSuffix class="mat-custom-arrow">{{input.suffixIcon}}</mat-icon>
        <mat-error *ngFor="let validation of validation_msgs">
            <div *ngIf="hasError(validation.type)">
              {{validation.message}}
            </div>
        </mat-error>
    </mat-form-field>
</form>