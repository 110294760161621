import { Injectable, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";
import { WaitErrorDialogsService } from './wait-error-dialogs.service';
import { DIALOG_TYPES } from '../constants';

@Injectable({
  providedIn: 'root'
})

export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private router: Router, private weds: WaitErrorDialogsService) { }

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      Sentry.createErrorHandler({
        showDialog: false,
      })
      this.router.navigate(['/']);
      // window.location.reload();
    } else {
      Sentry.createErrorHandler({
        showDialog: true,
      })
    }
    console.error(error);
  }

  handle(err: any): void {
    this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: 0, dontCloseAllDialogs: true, isApiFailureDialog: true })
  }
}
