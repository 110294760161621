<div class="request-activity-modal">
    <!-- Header -->
    <div class="modal-header">
        <div class="body-large white d-flex mt-1">
            <mat-icon class="{{data.titleColor}} fs-18 title-icon">{{data.titleIcon}}</mat-icon>
            <span class="h4 ml-2 {{data.titleColor}}">{{data.titleText}}</span>
        </div>
        <mat-icon class="clickable grey" mat-dialog-close>close</mat-icon>
    </div>
    <!-- Body -->
    <div class="m-4">
        <label for="description" class="body black fw-500">{{data.labelText}}</label>
        <mat-form-field appearance="outline" class="formfield w-100">
            <textarea matInput placeholder={{data.placeholder}} id="description" [formControl]="description"
                cdkTextareaAutosize [cdkAutosizeMinRows]="2" [cdkAutosizeMaxRows]="2"></textarea>
        </mat-form-field>
        <caption class="caption gray d-flex mt-2">{{data.infoText}}</caption>
    </div>
    <div class="modal-footer justify-content-between mt-5">
        <button mat-stroked-button mat-dialog-close class="btn d-flex align-items-center">
            <span class="h5 primary pink">CANCEL</span></button>
        <button mat-stroked-button (click)="onButtonClick()" [disabled]="!description.valid" class="btn d-flex align-items-center border-0 {{data.btnBG}}">
            <span class="h5 primary white">{{data.btnText}}</span></button>
    </div>
</div>