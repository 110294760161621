import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { startWith } from 'rxjs/internal/operators/startWith';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
  selector: 'app-rate-skills',
  templateUrl: './rate-skills.component.html',
  styleUrls: ['./rate-skills.component.scss']
})
export class RateSkillsComponent implements OnInit, OnChanges {
  @Input() config;
  @ViewChild('skillInput') skillInput: ElementRef;
  @Output() selectedItems = new EventEmitter();
  canEdit: boolean = false;
  searchControl = new FormControl('', [Validators.required]);
  displayOptions: any = [];
  availableItemsToDisplay: Observable<any>;
  showTextbox = false;
  data: any = {}
  newSkill: any = [];
  userSkillsName: any;
  client;
  ratingKey: string;
  selectedData = [];

  constructor(private ds: DataService, private weds: WaitErrorDialogsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedData = this.config.editObject;
  }

  ngOnInit(): void {
    this.client = this.ds.client;
    this.canEdit = this.config.canEdit;
    this.ratingKey = this.config?.optionsToDisplay?.key;
    this.initSearch();
  }

  initSearch() {
    if (this.config?.optionsToDisplay?.data.length) {
      this.selectedData = [...this.config?.editObject];
      this.displayOptions = this.config?.optionsToDisplay?.data;
      this.availableItemsToDisplay = this.searchControl.valueChanges.pipe(startWith(''), map(val => val ? this._availableItems(val) : this.displayOptions));
    } else {
      this.availableItemsToDisplay = this.searchControl.valueChanges.pipe(startWith(''), debounceTime(400), distinctUntilChanged(), switchMap(val => {
        const endPoint = `${API_ENDPOINT.SEARCH_SKILL_FOR_LE_PATH(this.ds.currentAdminClientId)}?search=${val}&page=1&limit=10`;
        return this.ds.careerPrismDataPostApi(endPoint, []).pipe(
          map(({data}) => data.map(({title, pk}) => ({name: title, [this.ratingKey]: pk}))));
        }));

      this.selectedData = [];
      this.config?.editObject.forEach(data => {
          this.selectedData.push({
            id: data.pk,
            value: data.rating,
            displayName: data.title,
          });
      });
    }
  }

  private _availableItems(value): any[] {
    if (value && value.name) return [value];
    return this.displayOptions.filter(skillItem => skillItem.name.toLowerCase().includes(value.toLowerCase()));
  }

  addItem(item) {
    this.showTextbox = false;
    for (let i = 0; i < this.selectedData.length; i++) {
      if (this.selectedData[i].id === item[this.ratingKey]) {
        this.searchControl.setValue(null);
        this.searchControl.markAsUntouched();
        this.weds.showDialog({ type: 'generic', title: 'Invalid Selection', message: 'You have already selected this skill', 'btnTitle': 'OK', dontCloseAllDialogs: true });
        return;
      }
    }
    this.selectedData.push({ displayName: item.name, id: item[this.ratingKey], value: 0 });

    this.searchControl.markAsUntouched();
    this.searchControl.setValue('');
    this.selectedItems.emit(this.selectedData);
  }

  deleteItem(item) {
    this.selectedData = this.selectedData.filter(ratingData => ratingData.id !== item.id);
    this.searchControl.markAsUntouched();
    this.selectedItems.emit(this.selectedData);
  }
  showTextboxOnClick() {
    this.showTextbox = true;
    if (this.showTextbox) {
      setTimeout(() => {
        this.skillInput.nativeElement.focus();
      }, 200)
    }
  }

  onSetRating(event) {
    this.selectedItems.emit(this.selectedData);
  }

  noSelectedItems() {
    this.showTextbox = true;
    setTimeout(() => {
      this.searchControl.markAllAsTouched();
    }, 100);
  }
}
