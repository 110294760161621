<div class="con" *ngIf="client?true:false">
    <div class="admin-panel">
        <div class="h3 black">Company Information: {{client.name}}</div>
        <div class="form-con">
            <form [formGroup]="infoForm" (ngSubmit)="onSaveInfo()" (keydown.enter)="$event.preventDefault()">
                <div class="input-con-half">
                    <mat-form-field appearance="outline" class="input-form-field">
                        <mat-label>Name</mat-label>
                        <mat-icon matPrefix>location_city</mat-icon>
                        <input matInput placeholder="Enter the name to be used in Prismforce" formControlName="name"
                            required autocomplete="off" />
                        <mat-error *ngIf="infoForm.get('name').invalid">{{infoForm.get('name').errors.error||'This input
                            is mandatory'}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="input-con-half">
                    <mat-form-field appearance="outline" class="input-form-field">
                        <mat-label>Legal Name</mat-label>
                        <mat-icon matPrefix>location_city</mat-icon>
                        <input matInput placeholder="Full legal name" formControlName="legalName" required
                            autocomplete="off" />
                        <mat-error *ngIf="infoForm.get('legalName').invalid">
                            {{infoForm.get('legalName').errors.error||'This input is mandatory'}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="input-con-full">
                    <app-multi-select-chip [input]="companyAliasNamesInput" formControlName="companyAliasNames">
                    </app-multi-select-chip>
                </div>
                <div class="input-con-half">
                    <mat-form-field appearance="outline" class="input-form-field">
                        <mat-label>License start date</mat-label>
                        <mat-icon matPrefix>date_range</mat-icon>
                        <input matInput placeholder="Select license start date" formControlName="licenseStartDt"
                            [matDatepicker]="picker1" required [min]="minLicenseStartDt" [max]="maxLicenseStartDt"
                            autocomplete="off" (focus)="picker1.open()" />
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-error *ngIf="infoForm.get('licenseStartDt').invalid">This input is mandatory</mat-error>
                    </mat-form-field>
                </div>
                <div class="input-con-half">
                    <mat-form-field appearance="outline" class="input-form-field">
                        <mat-label>License end date</mat-label>
                        <mat-icon matPrefix>date_range</mat-icon>
                        <input matInput placeholder="Select license end date" formControlName="licenseEndDt"
                            [matDatepicker]="picker2" required [min]="minLicenseEndDt" [max]="maxLicenseEndDt"
                            autocomplete="off" (focus)="picker2.open()" />
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-error *ngIf="infoForm.get('licenseEndDt').invalid">This input is mandatory</mat-error>
                    </mat-form-field>
                </div>
                <div class="input-con-full">
                    <mat-form-field appearance="outline" class="input-form-field">
                        <mat-label>Address</mat-label>
                        <mat-icon matPrefix>location_on</mat-icon>
                        <input matInput placeholder="Registered address" formControlName="address" required
                            autocomplete="off" />
                        <mat-error *ngIf="infoForm.get('address').invalid">{{infoForm.get('address').errors.error||'This
                            input is mandatory'}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="contacts-con">
                    <div class="regularfont">Company Contacts</div>
                    <div *ngFor="let contact of client.contacts" class="contacts-inputs-con">
                        <div class="input-con-third">
                            <mat-form-field appearance="outline" class="input-form-field">
                                <mat-label>Contact Name</mat-label>
                                <mat-icon matPrefix>person</mat-icon>
                                <input matInput placeholder="Name of the contact person"
                                    formControlName="{{'name_'+contact.id}}" required autocomplete="off" />
                                <mat-error *ngIf="infoForm.get('name_'+contact.id).invalid">
                                    {{infoForm.get('name_'+contact.id).errors.error||'This input is mandatory'}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="input-con-third">
                            <mat-form-field appearance="outline" class="input-form-field">
                                <mat-label>Email address</mat-label>
                                <mat-icon matPrefix>email</mat-icon>
                                <input matInput placeholder="Email address" formControlName="{{'email_'+contact.id}}"
                                    required autocomplete="off" type="email" />
                                <mat-error *ngIf="infoForm.get('email_'+contact.id).invalid">Enter a valid email address
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="input-con-third">
                            <mat-form-field appearance="outline" class="input-form-field">
                                <mat-label>Contact Number</mat-label>
                                <mat-icon matPrefix>phone</mat-icon>
                                <input matInput placeholder="Contact number" formControlName="{{'mobile_'+contact.id}}"
                                    required autocomplete="off" type="tel" />
                                <mat-error *ngIf="infoForm.get('mobile_'+contact.id).invalid">Enter a valid contact
                                    number
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <mat-icon class="delete-contact" (click)="deleteContact(contact)">delete_outline</mat-icon>
                    </div>
                </div>
                <div class="save-button-con" *ngIf="canEdit">
                    <button type="button" mat-stroked-button (click)="addContact()" class="secondary-button">Add
                        Contact</button>
                    <button type="submit" mat-stroked-button class="primary-button">Save</button>
                </div>
            </form>
        </div>
    </div>
    <div class="admin-panel">
        <div class="h3 black">Branding</div>
        <div class="logo-con">
            <div class="input-con-half">
                <div class="regularfont">SMS - Square Logo</div>
                <div class="img-flex-con">
                    <div class="img-con">
                        <input  type="file" style="visibility: hidden;"
                            class="fileInput" (change)="onFileSelected($event,'logoSquare')" #fileInputSquare
                            accept=".png" />
                        <app-svg-icon #logoSquare fill="var(--primary-color-light)" height="24px" viewBox="0 0 24 24" width="24px" src="icons/photo_upload.svg" matTooltip="Click here to add SMS square logo"
                            (click)="selectFile('fileInputSquare')"></app-svg-icon>
                    </div>
                </div>
            </div>
            <div class="input-con-half">
                <div class="regularfont">SMS - Rectangular Logo</div>
                <div class="img-flex-con">
                    <div class="img-con">
                        <input  type="file" style="visibility: hidden;"
                            class="fileInput" (change)="onFileSelected($event,'logoRect')" #fileInputRect accept=".png" />
                        <app-svg-icon #logoRect fill="var(--primary-color-light)" height="24px" viewBox="0 0 24 24" width="24px" src="icons/photo_upload.svg" matTooltip="Click here to add SMS rectangular logo" 
                            (click)="selectFile('fileInputRect')"></app-svg-icon>
                    </div>
                </div>
            </div>
            <div class="input-con-half">
                <div class="regularfont">SMS - Loading GIF</div>
                <div class="img-flex-con">
                    <div class="img-con">
                        <input type="file" style="visibility: hidden;" class="fileInput"
                            (change)="onFileSelected($event,'loadingGIF')" #fileInputLoadingGIF accept=".gif" />
                        <app-svg-icon #loadingGIF fill="var(--primary-color-light)" height="24px" viewBox="0 0 24 24" width="24px" src="icons/photo_upload.svg" matTooltip="Click here to add SMS Loading gif"
                            (click)="selectFile('fileInputLoadingGIF')"></app-svg-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="logo-con">
            <div class="input-con-half">
                <div class="regularfont">RMS - Square Logo</div>
                <div class="img-flex-con">
                    <div class="img-con">
                        <input type="file" style="visibility: hidden;"
                            class="fileInput" (change)="onFileSelected($event,'logoSquareRMS')" #fileInputSquareRMS accept=".png" />
                        <app-svg-icon #logoSquareRMS fill="var(--primary-color-light)" height="24px" viewBox="0 0 24 24" width="24px" src="icons/photo_upload.svg" matTooltip="Click here to add RMS square logo" 
                            (click)="selectFile('fileInputSquareRMS')"></app-svg-icon>
                    </div>
                </div>
            </div>
            <div class="input-con-half">
                <div class="regularfont">RMS - Rectangular Logo</div>
                <div class="img-flex-con">
                    <div class="img-con">
                        <input type="file" style="visibility: hidden;"
                            class="fileInput" (change)="onFileSelected($event,'logoRectRMS')" #fileInputRectRMS accept=".png" />
                        <app-svg-icon #logoRectRMS fill="var(--primary-color-light)" height="24px" viewBox="0 0 24 24" width="24px" src="icons/photo_upload.svg" matTooltip="Click here to add RMS rectangular logo"
                            (click)="selectFile('fileInputRectRMS')"></app-svg-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="logo-con">
            <div class="input-con-half">
                <div class="regularfont">OP - Square Logo</div>
                <div class="img-flex-con">
                    <div class="img-con">
                        <input type="file" style="visibility: hidden;"
                            class="fileInput" (change)="onFileSelected($event,'logoSquareOP')" #fileInputSquareOP accept=".png" />
                        <app-svg-icon #logoSquareOP fill="var(--primary-color-light)" height="24px" viewBox="0 0 24 24" width="24px" src="icons/photo_upload.svg" matTooltip="Click here to add OP square logo"
                            (click)="selectFile('fileInputSquareOP')"></app-svg-icon>
                    </div>
                </div>
            </div>
            <div class="input-con-half">
                <div class="regularfont">OP - Rectangular Logo</div>
                <div class="img-flex-con">
                    <div class="img-con">
                        <input  type="file" style="visibility: hidden;"
                            class="fileInput" (change)="onFileSelected($event,'logoRectOP')" #fileInputRectOP accept=".png" />
                        <app-svg-icon #logoRectOP fill="var(--primary-color-light)" height="24px" viewBox="0 0 24 24" width="24px" src="icons/photo_upload.svg" matTooltip="Click here to add OP rectangular logo"
                            (click)="selectFile('fileInputRectOP')"></app-svg-icon>
                    </div>
                </div>
            </div>
        </div>
        
        <form [formGroup]="brandForm" (ngSubmit)="onSaveBrand()" (keydown.enter)="$event.preventDefault()">
            <div class="input-con-half">
                <mat-form-field appearance="outline" class="input-form-field">
                    <mat-label>Primary Color Lightest (Hex)</mat-label>
                    <mat-icon matPrefix>palette</mat-icon>
                    <input matInput placeholder="Primary Color Lightest" formControlName="primaryColorLightest" autocomplete="off" />
                    <mat-error *ngIf="brandForm.get('primaryColorLightest').invalid">Invalid HEX color code. Must include # followed by 6 hexadecimal characters.</mat-error>
                </mat-form-field>
            </div>
            <div class="input-con-half">
                <mat-form-field appearance="outline" class="input-form-field">
                    <mat-label>Primary Color Light (Hex)</mat-label>
                    <mat-icon matPrefix>palette</mat-icon>
                    <input matInput placeholder="Primary Color Light" formControlName="primaryColorLight" autocomplete="off" />
                    <mat-error *ngIf="brandForm.get('primaryColorLight').invalid">Invalid HEX color code. Must include # followed by 6 hexadecimal characters.</mat-error>
                </mat-form-field>
            </div>
            <div class="input-con-half">
                <mat-form-field appearance="outline" class="input-form-field">
                    <mat-label>Primary Color (Hex)</mat-label>
                    <mat-icon matPrefix>palette</mat-icon>
                    <input matInput placeholder="Primary Color" formControlName="primaryColor" autocomplete="off" />
                    <mat-error *ngIf="brandForm.get('primaryColor').invalid">Invalid HEX color code. Must include # followed by 6 hexadecimal characters.</mat-error>
                </mat-form-field>
            </div>
            <div class="input-con-half">
                <mat-form-field appearance="outline" class="input-form-field">
                    <mat-label>Primary Color Dark (Hex)</mat-label>
                    <mat-icon matPrefix>palette</mat-icon>
                    <input matInput placeholder="Primary Color Dark" formControlName="primaryColorDark" autocomplete="off" />
                    <mat-error *ngIf="brandForm.get('primaryColorDark').invalid">Invalid HEX color code. Must include # followed by 6 hexadecimal characters.</mat-error>
                </mat-form-field>
            </div>
            <div class="input-con-half">
                <mat-form-field appearance="outline" class="input-form-field">
                    <mat-label>Accent Color (Hex)</mat-label>
                    <mat-icon matPrefix>palette</mat-icon>
                    <input matInput placeholder="Accent Color" formControlName="accentColor" autocomplete="off" />
                    <mat-error *ngIf="brandForm.get('accentColor').invalid">Invalid HEX color code. Must include # followed by 6 hexadecimal characters.</mat-error>
                </mat-form-field>
            </div>
            <div class="input-con-half">
                <mat-form-field appearance="outline" class="input-form-field">
                    <mat-label>Accent Color Dark (Hex)</mat-label>
                    <mat-icon matPrefix>palette</mat-icon>
                    <input matInput placeholder="Accent Color Dark" formControlName="accentColorDark" autocomplete="off" />
                    <mat-error *ngIf="brandForm.get('accentColorDark').invalid">Invalid HEX color code. Must include # followed by 6 hexadecimal characters.</mat-error>
                </mat-form-field>
            </div>
            <div class="input-con-half">
                <mat-form-field appearance="outline" class="input-form-field">
                    <mat-label>Disable Home Page Prism Logo</mat-label>
                    <mat-icon matPrefix>branding_watermark</mat-icon>
                    <mat-select formControlName="disablePrismLogo" >
                        <mat-option value="Yes">Yes</mat-option>
                        <mat-option value="No">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="save-button-con" *ngIf="canEdit">
                <button type="submit" mat-stroked-button class="primary-button">Save</button>
            </div>
        </form>

    </div>
    <div class="admin-panel" *ngIf="this.user.role.globalAdmin">
        <div class="h3 black">Prismforce features</div>
        <form [formGroup]="featuresForm" (ngSubmit)="onSaveFeatures()" (keydown.enter)="$event.preventDefault()">
            <div *ngFor="let input of featuresInputs" [ngClass]="input.class||'input-con-half'">

                <div *ngIf="input.titleText||featuresForm.get(input.accessKey).value=='Yes'">
                    <!-- <div *ngIf="(input.key=='allowMachineRating' && allowMachineRating.show)">
                        <div class="regularfont featuresTitle" *ngIf="input.titleText">{{input.titleText}}</div>
                        <mat-form-field appearance="outline" class="input-form-field">
                            <mat-label>{{input.title}}</mat-label>
                            <mat-icon matPrefix>{{input.icon}}</mat-icon>
                            <mat-select (selectionChange)="onValueChange($event,input)" formControlName="{{input.key}}"
                                required>
                                <mat-option *ngFor="let option of input.options" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="featuresForm.get(input.key).invalid">Please select an access option
                            </mat-error>
                        </mat-form-field>
                    </div> -->

                    <div class="regularfont featuresTitle" *ngIf="input.titleText && input.key!='allowMachineRating'">{{input.titleText}}</div>
                    <mat-form-field appearance="outline" class="input-form-field">
                        <mat-label>{{input.title}}</mat-label>
                        <mat-icon matPrefix>{{input.icon}}</mat-icon>
                        <mat-select formControlName="{{input.key}}"
                            required>
                            <mat-option *ngFor="let option of input.options" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="featuresForm.get(input.key).invalid">Please select an access option
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="save-button-con" *ngIf="canEdit">
                <button type="submit" mat-stroked-button class="primary-button">Save</button>
            </div>
        </form>
    </div>
</div>