import { IGoal } from "../../admin/admin-goal-management/components/goals";

export enum GoalType {
  SET = 'set',
  REVISED = 'revised',
  DELAYED = 'delayed',
  COMPLETED = 'completed',
  IN_REVIEW = 'inreview'
}

export enum GoalIcon {
  ALBUM = 'album',
  SCHEDULE = 'watch_later',
  WARNING = 'warning',
  PENDING = 'pending',
  TICK = 'offline_pin',
}

export enum GoalStatusType {
  PRIMARY = 'primary',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  SUCCESS = 'success',
}

export enum GoalStatusHeader {
  GOAL_ACTIVE_TITLE = 'Get active with your goals!',
  GOAL_ACTIVE_DESC = 'Dive in, take action, and make strides towards success.',
  GOAL_SET_TITLE = 'set',
  GOAL_SET_DESC = 'Set goals that you want to achieve in this time period.',
  DEFAULT_GOAL_TITLE = 'It’s time for goal setting!',
  DEFAULT_GOAL_DESC = 'Set goals that you want to achieve in this time period'
}

export interface IGoalStatus {
  label: string;
  desc?: string;
  icon?: GoalIcon;
  color?: string;
  type?: GoalStatusType;
  value?: number;
  goalStatus?: string;
  isNoGoals?: boolean;
}

export interface IApprovalStatus {
  id: string;
  approvalStatusCode: string;
  label: string;
}

export interface IGoalResponse {
  ok: boolean;
  data: {
    goals: IGoal[];
    totalCount: number ;
    userIdVsProfileImg?: string;
  }
}

export interface IGoalOverviewResponse {
  ok: boolean;
  GOAL_SET: IStatusItem[];
  NOT_REQUIRED: IStatusItem[];
  PENDING: IStatusItem[];
  // [key: string]: boolean | IStatusItem[];
}

export interface IStatusItem {
  _id: string;
  progressStatus: string;
  approvalStatus: string;
}

export interface IPayloadCheckIn {
  data: {
    goalId?: string;
    progressStatus?: string;
    currentValue?: number;
  };
}
