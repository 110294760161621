<form [formGroup]="chipEditForm" [hidden]="input.hidden">
    <mat-form-field class="input-form-field" appearance="outline">
        <mat-label *ngIf="input.title">{{input.title}}</mat-label>
        <mat-icon *ngIf="input.icon" matPrefix>{{input.icon}}</mat-icon>
        <input matInput [placeholder]="input.placeholder"
                formControlName="chipInput" (keyup.enter)="onAdd($event)"
                (focusout)="onBlur()"/>
        <mat-error *ngIf="chipEditForm.invalid">{{input.errorText||'This input is
            mandatory'}}</mat-error>
    </mat-form-field>
    <div *ngIf="input?.subTitle" class="caption gray mb-2">{{input?.subTitle}}</div>
    <mat-chip-list class="chip-row">
        <mat-chip *ngFor="let item of selectedItems" class="chip"
            (removed)="onRemove(item)" [disabled]="chipEditForm.controls['chipInput'].disabled ">{{item}}
            <button matChipRemove>
                <mat-icon>close</mat-icon>
            </button>
        </mat-chip>
    </mat-chip-list>
</form>