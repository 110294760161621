import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { SkillRatingFilterDropdownDialogComponent } from './skill-rating-filter-dropdown-dialog/skill-rating-filter-dropdown-dialog.component';

@Component({
  selector: 'app-skill-rating-filter-dropdown',
  templateUrl: './skill-rating-filter-dropdown.component.html',
  styleUrls: ['./skill-rating-filter-dropdown.component.scss']
})
export class SkillRatingFilterDropdownComponent implements OnInit {
  @Input() data;
  title: string;
  @ViewChild('ddButton') ddButton: ElementRef;
  @Output() onApply = new EventEmitter();
  isDDOpen = false;
  hasSelectedItems = false;
  minRating = 0;
  maxRating = 5;
  currentMinRating = 0;
  currentMaxRating = 5;
  constructor(private dialog: MatDialog, private ds: DataService) { }

  ngOnInit(): void {
    let client = this.ds.client;
    if (client.smsSettings.skillRating == 'Yes/No') { this.maxRating = 1; this.currentMaxRating = 1; }
    else if (client.smsSettings.skillRating == 'Numeric: 1-10') { this.maxRating = 10; this.currentMaxRating = 10; }
    else if (client.smsSettings.skillRating == 'Stars 1-4') { this.maxRating = 4; this.currentMaxRating = 4; }
    if (this.data.currentMinRating !== undefined) this.currentMinRating = this.data.currentMinRating;
    if (this.data.currentMaxRating !== undefined) this.currentMaxRating = this.data.currentMaxRating;
    if (!this.data.selectedItems) this.data.selectedItems = [];
    this.calculateTitle();
  }
  toggleDropdown() {
    if (!this.ddButton) return;
    const rect = this.ddButton.nativeElement.getBoundingClientRect();
    const dialogRef = this.dialog.open(SkillRatingFilterDropdownDialogComponent, { width: '500px', height: '195px', panelClass: 'dd-modal', position: { left: (rect.left - 200 + rect.width) + 'px', top: (rect.bottom + 4) + 'px' }, backdropClass: 'dd-modal-backdrop' } as MatDialogConfig);
    dialogRef.componentInstance.currentMinRating = this.currentMinRating;
    dialogRef.componentInstance.currentMaxRating = this.currentMaxRating;
    dialogRef.componentInstance.title = this.data.title;
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      this.currentMinRating = data.currentMinRating;
      this.currentMaxRating = data.currentMaxRating;
      this.calculateTitle();
      dialogRef.close();
    });
    dialogRef.afterClosed().subscribe(() => { this.isDDOpen = false; onEvent.unsubscribe(); if(this.onApply) this.onApply.emit({ maxRating: this.currentMaxRating, minRating: this.currentMinRating}) });
    this.isDDOpen = true;
  }
  calculateTitle() {
    this.title = this.data.title + ': ' + this.currentMinRating + ' - ' + this.currentMaxRating;
    this.hasSelectedItems = this.currentMinRating == this.minRating && this.currentMaxRating == this.maxRating ? false : true;
    this.data.selectedItems = this.hasSelectedItems ? [{ key: this.data.key, minRating: this.currentMinRating, maxRating: this.currentMaxRating }] : [];
  }
  resetFilters() {
    this.currentMinRating = this.minRating;
    this.currentMaxRating = this.maxRating;
    this.data.selectedItems = [];
    this.hasSelectedItems = false;
    this.calculateTitle();
  }

}
