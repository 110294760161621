export const CANCEL = {
    titleIcon: 'do_not_disturb_on',
    titleText: 'Cancel Resume',
    titleColor: 'grey',
    labelText: 'Cancellation Reason',
    btnText: 'CANCEL RESUME',
    btnBG: 'bg-grey',
    placeholder:'Why are you cancelling the resume? Describe in brief.',
    infoText: 'This will be shown to the Employee.'
}
export const DECLINE = {
    titleIcon: 'cancel_schedule_send',
    titleText: 'Decline Handover',
    titleColor: 'red',
    labelText: 'Decline Reason',
    btnText: 'DECLINE',
    btnBG: 'bg-red',
    placeholder: 'Type your reason here...',
    infoText: 'This will be shown to the user who has handover this to you.'
}
export const REJECT_REQUEST = {
    titleIcon: 'cancel',
    titleText: 'Reject Request',
    titleColor: 'red',
    labelText: 'Rejection Reason',
    btnText: 'REJECT REQUEST',
    btnBG: 'bg-red',
    placeholder: 'Type your reason here...',
    infoText: 'A reason is mandatory to reject a skill addition request.'
}
export const DEFAULT = {
    titleIcon: 'cancel',
    titleText: 'Reject Resume',
    titleColor: 'red',
    labelText: 'Rejection Reason',
    btnBG: 'bg-red',
    btnText: 'REJECT RESUME',
    placeholder: 'Why are you rejecting the resume? Describe in brief.',
    infoText: 'This will be shown to the Employee.'
}