import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[skeletonNew]'
})
export class SkeletonNewDirective implements OnChanges {
  @Input() isLoading: boolean;
  @Input() skeletonWidth: string;
  @Input() skeletonHeight: string;
  @Input() skeletonBorderRadius: string;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.isLoading) {
      this.elementRef.nativeElement.classList.add('skeleton-loader');
      this.elementRef.nativeElement.style.width = this.skeletonWidth;
      this.elementRef.nativeElement.style.height = this.skeletonHeight;
      this.elementRef.nativeElement.style.borderRadius = this.skeletonBorderRadius || '12px !important';
    } else {
      this.elementRef.nativeElement.classList.remove('skeleton-loader');
      this.elementRef.nativeElement.style.width = '';
      this.elementRef.nativeElement.style.height = '';
      this.elementRef.nativeElement.style.borderRadius = '';
    }
  }

}
