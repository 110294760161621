export class Role {
    name: string;
    globalAdmin: any;
    admin: any;
    sms: any;
    filters: any;

    deserialize(input: any) {
        if (!input) return null;
        Object.assign(this, input.features);
        for (let key in input) if (key != 'features') this[key] = input[key];
        return this;
    }
}

export interface IUserRole {
    name: string;
    search: string;
    clientId: string;
    features: {
      sms: {
        [key: string]: string;
      };
      skillClusterTags?: any[];
      filters: {
        [key: string]: string;
      };
    };
    updatedBy: string;
    autoGeneratedRole: {
      name: string;
    };
    designationSearch: string;
    updatedBySlUserId: string;
    roleId: string;
    searchDisplayText: string;
    isSystemGeneratedDefaultRole?: string;
}

export interface ISkillClusterTags {
    tagType: string;
    tagValues?: ITagValue[];
    restriction: string;
    attributeType: string;
}
  
export interface ITagValue {
    _id: string;
    userId: string;
    enabled: boolean;
    tagType: string;
    clientId: string;
    tagValue: string;
    searchKey: string;
    attributeType: string;
}
  