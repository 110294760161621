<main class="upload-con">
    <div class="h3 black ">Bulk upload {{input.entityAlias}}</div>
    <div class="caption gray">Processing starts 5 mins after uploading. You can cancel the processing before that</div>
    <button class="saveTemplateButton" (click)="generateTemplate()">Generate Template</button>
    <div class="form-con">
        <div class="file-con" (click)="onFileClick($event)">
            <input type="file" matTooltip="Upload a CSV file" id="fileInput" style="visibility: hidden;"
                (change)="onFileSelect($event)" accept=".csv" #fileInput />
            <div #fileName id="fileName">Click here to select a .csv file</div>
        </div>
    </div>
    <div class="save-button-con">
        <button mat-stroked-button class="primary-button" type="button" (click)="onUpload()">Upload</button>
    </div>
</main>
