import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-three-dots-animation',
  templateUrl: './three-dots-animation.component.html',
  styleUrls: ['./three-dots-animation.component.scss']
})
export class ThreeDotsAnimationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
