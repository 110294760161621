import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-spider-chart',
  templateUrl: './spider-chart.component.html',
  styleUrls: ['./spider-chart.component.scss']
})
export class SpiderChartComponent implements OnInit {
  @Input() chartData: any;
  @ViewChild('canvas') canvas: ElementRef;
  @Input() size = "full";
  @Input() width = 180;

  constructor(private ds: DataService) { }

  public radarChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: { displayColors: false }
    },
    elements: {
      line: {
        borderWidth: 1,
      },
      point: {
        radius: 5
      }
    },
    layout: { padding: 5 },
    scales: {
      r: {
        angleLines: {
          display: true
        },
        grid: {
          circular: true,
        },
        pointLabels: {
          display: false
        },
        ticks: {
          display: false,
          stepSize: 1,
        },
        beginAtZero: true
      },
    }
  };

  public skillGroups = [];
  public skillLevels = [];
  public radarChartData: ChartData<'radar'> = {
    labels: [],
    datasets: [{
      data: [],
      label: `Your Rating`,
      fill: true,
      backgroundColor: 'rgba(54,162,235,0.2)',
      borderColor: 'rgba(54,162,235,1)',
      pointBackgroundColor: 'rgba(54,162,235,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(54,162,235,1)'
    }],
  };

  ngOnInit(): void {
    const smsSettings = this.ds.client.smsSettings;
    for (let index = 1; index <= 5; index ++) {
      this.skillLevels.push(smsSettings[`skillToolTipLabel${index}`])
    }
    this.skillGroups = this.chartData.categories;
    this.radarChartData.labels = this.chartData.labels;
    this.radarChartData.datasets[0].data = this.chartData.data;
    this.radarChartOptions.plugins.tooltip.callbacks = {
      label: (context) => {
        return (
          context.dataset.label + ': ' + (this.skillLevels[Number(context.formattedValue) - 1])
        );
      },
    };
  }
}
