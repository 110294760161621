
<div class="upload-con d-flex flex-column border-radius-8">
    <div class="header-con d-flex justify-content-between align-items-center border-box">
        <div>
            <div class="d-flex h4 white gap-1">
                <mat-icon matTooltip="Upload Skills" class="right-icon white">cloud_upload</mat-icon>
                Bulk Upload
            </div>
            <label class="body white">Processing starts 5 mins after uploading. You can cancel the processing before that</label>
        </div>
        <mat-icon class="cursor-pointer white" (click)="onCloseClick()">close</mat-icon>
    </div>

    <div class="d-flex align-self-stretch p-20">
       <div class="d-flex flex-column justify-content-center align-items-start align-self-stretch p-4 gap-20 w-100 solid-border">
            <div class="d-flex flex-column gap-10 w-100">
                <div class="d-flex align-self-stretch justify-content-end w-100">
                    <button class="label primary border-0 bg-transparent text-uppercase"
                    (click)="generateTemplate()">Generate template</button>
                </div>
                <input type="file" matTooltip="Upload a CSV file" id="fileInput" class="d-none"
                (change)="onFileSelect($event)" accept=".csv" #fileInput />
                <div #fileName id="fileName"(click)="onFileClick($event)" class="d-flex justify-content-center p-20 dashed-border primary cursor-pointer">
                    Click here to select a .csv file
                </div>
            </div>

            <div class="d-flex justify-content-center w-100">
                <button mat-stroked-button class="primary-button border-0 text-uppercase"
                    (click)="onUpload($event)">Upload</button>
            </div>       
       </div>
    </div>
</div>

