import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { IProgressCard } from '../progress-card/progress-card.model';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IFilter, ILearningElementCardData } from '../../my-learnings/my-learning.model';

@Component({
  selector: 'app-progress-card-group',
  templateUrl: './progress-card-group.component.html',
  styleUrls: ['./progress-card-group.component.scss'],
})
export class ProgressCardGroupComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() config;
  @ViewChild('cardHolder') cardHolder: ElementRef;
  isExpanded: boolean = false;
  isLoading: boolean = true;
  previousPage: number = null;
  nextPage: number = 1;
  limit: number = 20;
  totalDataCount: number = 20;
  numberOfCardsToShow: number = 4;
  cardsShown = [];
  allCards = [];

  private fetchData = new Subject<IFilter[]>();
  private destroy = new Subject<IFilter[]>();

  constructor(
    private router: Router,
    private ds: DataService,
    private weds: WaitErrorDialogsService,
    private cdr: ChangeDetectorRef
  ) { 
    this.initFilterSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.config.firstChange && this.config.getDataFromParent) {
      this.setComponent();
    } else if (changes.config) {
      this.getData(this.config.filters || []);
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  ngAfterViewInit(): void {
    this.setComponent();
    this.cdr.detectChanges();
  }

  setComponent() {
    this.setNumberOfCardsToShow();
    if (this.config.getDataFromParent) {
      this.processData(this.config.cards || []);
      this.totalDataCount = this.config.totalDataCount;
    }
    this.setSkeletonLoaders();
  }

  getData(filters: IFilter[]) {
    this.fetchData.next(filters);
  }

  processData(cards: Array<IProgressCard>) {
    this.cardsShown = cards?.length ? [...cards] : [];
    // if (this.cardsShown?.[0]?.pk) {
    //   const total = 15;
    //   for (let i = 1; i < total; i++) {
    //     this.cardsShown.push(this.cardsShown[0]);
    //   }
    // }
    this.allCards = [...this.cardsShown];
    if (!this.config.hideViewMoreBtn) {
      this.cardsShown = this.cardsShown.slice(0, this.numberOfCardsToShow);
    }
    this.isLoading = false;
  }

  setNumberOfCardsToShow() {
    //299 = 283 (min-width of card) + 16(col-gap) // 273+ 16 = 289
    const cardCountInOneRow = Math.floor((this.cardHolder?.nativeElement?.clientWidth + 16) / 289) || 4;
    this.numberOfCardsToShow = this.config.hideViewMoreBtn ? cardCountInOneRow * 3 : cardCountInOneRow;
  }

  setSkeletonLoaders() {
    if (!this.cardsShown?.[0]?.pk) {
      this.cardsShown = new Array(this.numberOfCardsToShow).fill(0);
    }
  }

  toggleContainerExpansion() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      this.cardsShown = [...this.allCards];
    } else {
      this.processData(this.allCards);
    }
  }

  redirectToViewAll() {
    this.router.navigate([this.router.url + '/viewAll'], {
      queryParams: {
        ...this.config,
        hideViewMoreBtn: true,
        getDataFromParent: true,
        filters: JSON.stringify(this.config?.filters || {}),
        showGrayBG: true
      } 
    });
  }

  initFilterSubscription() {
    this.fetchData
      .pipe(
        switchMap((filters: IFilter[]) => {
          const url = `${this.config.getDataApi}?page=${this.nextPage||1}&limit=${this.limit}`;
          return this.ds[this.config.getDataApiMethod](url, { filters });
        }),
        takeUntil(this.destroy)
      )
      .subscribe(
        (res: ILearningElementCardData) => {
          this.previousPage = res.previous;
          this.nextPage = res.next;
          this.totalDataCount = res.count;
          this.processData(this.config.responseFormat ? res.data?.[0]?.data : res?.data);
        },
        (err) => {
          this.weds.showDialog({
            type: 'generic',
            code: 0,
          });
        }
      );
  }
}
