import { SearchableMultiselect } from "src/app/modules/common-components/searchable-multiselect/searchable-multiselect.model";

export const TYPE = '_type';
export const NAME = '_name';
export const SKILL_ITEM = 'Skill Item'
export const ORDER = '_levelOrder';
export const SKILL_TREE = 'skillTree';
export const SKILL_ARRAY = 'skillArray';

export interface IBrowseSkillData {
    categoryId: string,
    name: string, 
    skills: ISkill[], 
    filters: Array<SearchableMultiselect>,
    order?: number,
    hideSecondLevelHierarchy?: boolean,
    hideThirdLevelHierarchy?: boolean,
}

export interface ISkill {
    name: string,
    id: string,
    selected: boolean,
    order: number,
    tooltip: string,
    skillCategory: { categoryId: string, name: string, order: number },
    level2Id?: string,
    level2Name?: string
    level3Id?: string,
    level3Name?: string,
    level4Id?: string,
    level4Name?: string,
    level5Id?: string,
    level5Name?: string,
    level6Id?: string,
    level6Name?: string,
    level7Id?: string,
    level7Name?: string,
}

export interface IFilter {
    name: string,
    id: string,
    selected: boolean,
    level2Id?: Set<string>,
    level2Name?: Set<string>,
    level3Id?: Set<string>,
    level3Name?: Set<string>,
    level4Id?: Set<string>,
    level4Name?: Set<string>,
    level5Id?: Set<string>,
    level5Name?: Set<string>,
    level6Id?: Set<string>,
    level6Name?: Set<string>,
    level7Id?: Set<string>,
    level7Name?: Set<string>,
}