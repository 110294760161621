import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { fromEvent } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AutoCompleteMultiselect } from './auto-complete-multiselect.model';


@Component({
  selector: 'app-auto-complete-multiselect',
  templateUrl: './auto-complete-multiselect.component.html',
  styleUrls: ['./auto-complete-multiselect.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: AutoCompleteMultiselectComponent,
    multi: true
  }]
})
export class AutoCompleteMultiselectComponent implements OnInit, ControlValueAccessor {

  @Input() input: AutoCompleteMultiselect
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('auto') autoComplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  editForm: FormGroup;
  options = new Set();
  optionsCache = new Set()
  selectedItems = new Set();
  limit: number = 20;
  offset: number = 0;
  searchVal: any;
  stopScrollFiring: boolean = false;

  constructor(private ds: DataService, private fb: FormBuilder, private util: UtilitiesService) { }

  ngOnInit(): void {
    let selectedValue;
    if (this.input.value) {
      selectedValue = this.input.value;
    }
    this.editForm = this.fb.group({
      input: new FormControl({ value: selectedValue, disabled: this.input.disabled }, this.input.validators || [])
    });
    this.input.selectedItems?.forEach((item) => this.selectedItems.add(item));
    if (this.input?.options?.length) {
      this.editForm.controls['input'].valueChanges.pipe(startWith(''), map((v: string) => this.input.options.filter((f) => { this.options =  (f.name || f).toLowerCase().includes((v + '').toLowerCase()) })));
    } else {
      this.editForm.controls['input'].valueChanges.pipe(debounceTime(400)).subscribe(val => {
        this.offset = 0;
        this.clearDropdownOptions();
        this.searchVal = val;
        this.getData();
      });
    }
  }

  getData() {
    if(this.offset === 0) {
      this.clearDropdownOptions();
    }
    const searchApiFunction = this.input.searchFunctionName || 'searchData';
    this.ds[searchApiFunction](this.input.endpoint, {attributeType: this.input.key, search: this.searchVal, limit: this.limit, forAutoComplete: false, type: this.input.searchType, clientId: this.ds.currentAdminClientId, offset: this.offset, apiRoute: this.input.apiRoute}).subscribe(response => {
      let newData = [];

      if(response['searchData']) {
        newData = response['searchData'];
      } else if(this.input.responseKey && Array.isArray(response[this.input.responseKey])) {
        newData = response[this.input.responseKey];
      } else if (this.input.responseKey && response['data'] && response['data']?.[this.input.responseKey]) {
        newData = response['data'][this.input.responseKey];
      }
      if(newData.length < this.limit) {
        this.stopScrollFiring = true;
      } else {
        this.stopScrollFiring = false;
      }
      this.options = new Set([...this.optionsCache, ...newData]);
      this.optionsCache = new Set([...this.options]);
    });
  }

  onSelectionChange(evt: any) {
    this.editForm.controls.input.setValue('');
    this.util.pushUniqueValue(this.selectedItems, evt.option.value);
    this.selectionChange.emit({inputObj: this.input, selectedItems: [...this.selectedItems]});
  }

  removeRole(item) {
    this.selectedItems.delete(item);
    this.selectionChange.emit({inputObj: this.input, selectedItems: [...this.selectedItems]});
  }

  getDisplayName = (opt: any) => {
    return '';
  }

  clearDropdownOptions() {
    this.options.clear();
    this.optionsCache.clear();
  }

  autocompleteScroll() {
    setTimeout(() => {
      if (this.autoComplete && this.autocompleteTrigger && this.autoComplete.panel) {
        fromEvent(this.autoComplete.panel.nativeElement, 'scroll')
          .pipe(
            map(x => this.autoComplete.panel.nativeElement.scrollTop),
            takeUntil(this.autocompleteTrigger.panelClosingActions)
          )
          .subscribe(x => {
            const scrollTop = this.autoComplete.panel.nativeElement.scrollTop;
            const scrollHeight = this.autoComplete.panel.nativeElement.scrollHeight;
            const elementHeight = this.autoComplete.panel.nativeElement.clientHeight;
            const atBottom = scrollHeight == scrollTop + elementHeight;
            if (atBottom && !this.stopScrollFiring) {
              this.offset += 20;
              this.getData();
            }
          });
      }
    });
  }

  //Control Value accessor methods
  set value(val: string){
      this.onChange(val)
      this.onTouch(val)
  }

  writeValue(value: any){
    this.selectedItems = new Set(value);
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(onTouched: Function) {
    this.onTouch = onTouched;
  }

  onChange: any = () => {}
  onTouch: any = () => {}
}
