<div class="my-growth-plans-sec mt-4 mb-4 custom-slider">
  <div class="header d-flex justify-content-between gap-1">
    <div class="label-info d-flex align-items-center gap-1">
      <mat-icon>explore</mat-icon>
      {{label}}
      <app-svg-icon class="ml-1 info-icon" #image src="icons/info.svg" fill="var(--primary-color)"></app-svg-icon>
    </div>
    <div class="view-all d-flex align-items-center clickable" (click)="onViewAll()">
      VIEW ALL
      <mat-icon>arrow_right_alt</mat-icon>
    </div>
  </div>
  <div class="d-flex align-items-center position-relative">
    <mat-icon *ngIf="myGrowthPlanData?.data" (click)="scrollLeft()" [attr.disabled]="scrollLeftDisabled" [ngClass]="{'disabled-left': scrollLeftDisabled}" class="scroll-btn p-2 clickable position-absolute left-btn">chevron_left</mat-icon>
    <div class="custom-container d-flex flex-fill gap-3 overflow-hidden" #cardsWrapper>

      <div class="data-con w-100" *ngIf="!myGrowthPlanData?.data">
        <div class="no-data-sec item-center">
          <img height="64px" width="64px" src="assets/nodata.png" />
          <div class="h5">No active/upcoming growth plans to show</div>
        </div>
      </div>

      <app-my-growth-plan-individual-card
        *ngFor="let myGrowthPlanObj of myGrowthPlanData?.data"
        [myGrowthPlanObj]="myGrowthPlanObj"
        [goalsCardData]="goalsCardData"
        (click)="navigateToGrowthPlan(myGrowthPlanObj)">
      </app-my-growth-plan-individual-card>
    </div>
    <mat-icon *ngIf="myGrowthPlanData?.data" (click)="scrollRight()" [attr.disabled]="scrollRightDisabled" [ngClass]="{'disabled-right': scrollRightDisabled}"
      class="scroll-btn p-2 cursor-pointer position-absolute right-btn">chevron_right</mat-icon>
  </div>
</div>
