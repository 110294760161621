<div class="chart-body" *ngIf="data"
    [ngStyle]="{'flex-direction': (showChange === 'Below' || showChange === 'None-Below') ? 'column' : 'row'}">
    <div class="chart"
        [ngStyle]="{'height': (size === 'full' ? width : width / 2) + 'px', 'width': width + 'px', 'margin-right': showChange === 'Right' ? '14px' : 0}">
        <div class="chart h2 black">
            <div class="chart-inner"
                [ngClass]="{'chart-inner-full': size === 'full', 'chart-inner-half': size === 'half', 'chart-inner-adjusted': colorType?.includes('onboarding')}">
                {{data.value}}{{percentString}}
                <span *ngIf="subtext" class="subtext">{{subtext}}</span>
            </div>
            <canvas #canvas [height]="(size === 'full' ? width : width / 2) + 'px'" [width]="width" baseChart
                [datasets]="datasets" [labels]="labels" [colors]="colors" type="doughnut" [options]="options">
            </canvas>
        </div>
    </div>
    <div class="change-con" *ngIf="showChange !== 'None' && showChange !== 'None-Below'">
        <div class="change-value-con">
            <app-svg-icon *ngIf="data.change" class="change-direction mr-2"
                [src]="'icons/' + (data.change > 0 ? 'change-up.svg' : 'change-down.svg')"></app-svg-icon>
            <div class="caption gray change-value" [ngStyle]="{'color': data.change >= 0 ? '#0ac5a1' : '#d90056'}">
                {{data.change}}%
            </div>
        </div>
        <div class="caption gray">vs last quarter</div>
    </div>
</div>
<div class="legend-con" *ngIf="showLegend">
    <div class="legend-con-item-wrapper" *ngFor="let label of labels; let i = index">
        <div class="legend-con-item-label">
            <span [style.background]="colors[i]" class="legend-con-item-legend"></span>
            {{label}}
        </div>
        <div class="legend-con-item-number">
            {{data.values[i]}}
        </div>
    </div>
</div>