import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SvgCacheService } from 'src/app/services/svg-cache.service';

@Component({
  selector: 'app-svg-icon',
  template: `
    <div style="display: inline-flex" [style.color]="fill">
      <svg
        class="class"
        [attr.width]="width"
        [attr.height]="height"
        [attr.fill]="fill"
        [attr.viewBox]="viewBox"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g [innerHTML]="iconContent | safeHTML"></g>
      </svg>
    </div>
  `
})
export class SvgIconComponent implements OnInit, OnChanges {
  @Input() src: string;
  @Input() class: string;
  @Input() fill: string = "var(--primary-color)";
  @Input() height: string;
  @Input() width: string;
  @Input() viewBox: string;

  iconContent: string;

  constructor(private svgCacheService: SvgCacheService) {}

  ngOnInit() {
    this.fetchSvg();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.src && !changes.src.firstChange) {
      this.fetchSvg();
    }
  }

  private fetchSvg(): void {
    this.svgCacheService.fetchSvg(this.src)
      .then(({ svgContent, viewBox, height, width }: any) => {
        this.iconContent = svgContent;
        this.viewBox = this.viewBox ?? viewBox;
        this.height = this.height ?? height;
        this.width = this.width ?? width;
      })
      .catch(error =>console.error('Error fetching SVG:', error));
  }
}
