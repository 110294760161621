import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Client } from '../models/client.model';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class CommonCalculationsService {
  constructor(private ds: DataService) {}
  calculateOverallRating(skillRatings: any[], skillItem) {
    let n = 0,
      d = 0;
    skillRatings.map((item) => {
      if (
        item.useRating != 'Yes' ||
        item.dataKey == 'aspirationalRating' ||
        !skillItem[item.dataKey]
      )
        return;
      n += skillItem[item.dataKey] * item.weight;
      d += item.weight * 1;
    });
    skillItem.overallRating = d > 0 ? Math.round((n * 10) / d) / 10 : 0;
  }

  processUserSpecialization(
    spec: any,
    userSkills = [],
    userSpecializations = [],
    allSpecializations = [],
    client = null
  ) {
    const entryCriteria = {
        mandatory: [],
        conditionalMandatory: [],
        optional: [],
        canApply: true,
      },
      cmHash = {},
      prerequisites = [];
    let qualifiesPrerequisites = 0,
      totalSkills = 0,
      qualifiesSkills = 0
    // Get the settings
    const {
      categoryWiseSettings,
      specializationRequestsEnabled,
      useAdditionalSkillFields = [],
      useAdditionalMasterFields = [],
    } = client.smsSettings;
    const isQualificationRatingEnabled = useAdditionalSkillFields.includes('Qualification Rating');
    const isPreRequisiteSpecEnabled = useAdditionalMasterFields.includes("Pre-requisite Specializations");
    const invalidPairings = [];
    // Loop through each skill item
    spec.skillItems = spec.skillItems.map((specSkillItem: any) => {
      // Category Settings from category wise settings
      const skillCategorySettings = categoryWiseSettings.find(
        ({ categoryId }) =>
          categoryId == (specSkillItem.skillCategory || {}).categoryId
      );
      if (!skillCategorySettings) return;
      if(!specSkillItem.exitRating) { specSkillItem.exitRating = 0 }
      if(specSkillItem.category == 'Conditional Mandatory' && specSkillItem.pairing) {
        invalidPairings.push(specSkillItem.pairing);
      }
      // Get enabled ratings only
      const enabledRatings = (skillCategorySettings.skillRatings || [])
        .filter((skillRating: any) => skillRating.useRating == 'Yes')
        .map(({ dataKey }) => dataKey);
      // Populate qualificationRatingTypes based on settings
      if (isQualificationRatingEnabled) {
        specSkillItem.qualificationRatingTypes = (
          specSkillItem.qualificationRatingTypes || []
        ).filter((ratingType: any) => enabledRatings.includes(ratingType));
      } else {
        specSkillItem.qualifies = true;
      }
      // Populate exitRatingTypes based on settings
      specSkillItem.exitRatingTypes = (
        specSkillItem.exitRatingTypes || []
      ).filter((ratingType: any) => enabledRatings.includes(ratingType));
      // Calculate the max rating of QualificationRating and ExitRating
      specSkillItem.maxUserQualificationRating = 0;
      specSkillItem.maxUserExitRating = 0;
      const userSkillItem = userSkills.find(
        (userSkill) => userSkill.skillItemId == specSkillItem.skillItemId
      );
      if (userSkillItem) {
        specSkillItem.maxUserQualificationRating = specSkillItem
          ?.qualificationRatingTypes?.length
          ? Math.max(
              ...specSkillItem.qualificationRatingTypes.map((rating: any) =>
                userSkillItem[rating] ? userSkillItem[rating] * 1 : 0
              )
            )
          : 0;
        specSkillItem.maxUserExitRating = userSkillItem.pastRating ?? specSkillItem.exitRatingTypes
          .length
          ? Math.max(
              ...specSkillItem.exitRatingTypes.map((rating: any) =>
                userSkillItem[rating] ? userSkillItem[rating] * 1 : 0
              )
            )
          : 0;
      } else {
        specSkillItem.qualifies = false;
      }
      // Calculate the rating numbers
      if (
        isQualificationRatingEnabled &&
        specSkillItem.qualificationRating &&
        specSkillItem.qualificationRatingTypes.length
      ) {
        specSkillItem.qualifies = !(
          !userSkillItem ||
          specSkillItem.maxUserQualificationRating <
            specSkillItem.qualificationRating * 1
        );
      } else if(specSkillItem.qualifies === undefined) {
        specSkillItem.qualifies = true;
      }
      // Category check
      if (specSkillItem.category == 'Optional') {
        entryCriteria.optional.push(specSkillItem);
      } else if (specSkillItem.category == 'Mandatory') {
        // Skill Gaps and can Apply logic for Mandatory skills
        if (!specSkillItem.qualifies) entryCriteria.canApply = false;
        if (specSkillItem.maxUserExitRating >= specSkillItem.exitRating && specSkillItem.exitRating) qualifiesSkills++;
        entryCriteria.mandatory.push(specSkillItem);
        totalSkills++;
      } else {
        if (!cmHash[specSkillItem.pairing]) {
          cmHash[specSkillItem.pairing] = {
            numMandatorySkillsForPairing:
              specSkillItem.numMandatorySkillsForPairing || 1,
            qualifies: false,
            skillItems: [],
            pairing: specSkillItem.pairing,
          };
        }
        cmHash[specSkillItem.pairing].skillItems.push(specSkillItem);
        if (
          cmHash[specSkillItem.pairing].skillItems.filter((skillItem : any) => skillItem.qualifies).length >= cmHash[specSkillItem.pairing].numMandatorySkillsForPairing
        )
          cmHash[specSkillItem.pairing].qualifies = true;
      }
      // PreRequisites Skills
      if (
        specSkillItem.qualificationRatingTypes &&
        specSkillItem.qualificationRatingTypes.length &&
        specSkillItem.category != 'Optional'
      ) {
        prerequisites.push({
          name: specSkillItem.name,
          type: 'Skill Item',
          qualifies: specSkillItem.qualifies,
        });
        if (specSkillItem.qualifies) qualifiesPrerequisites++;
      }
      return specSkillItem;
    });
    spec.skillItems = spec.skillItems.filter((specSkillItem: any) => specSkillItem && (specSkillItem?.category != 'Conditional Mandatory' || invalidPairings.indexOf(specSkillItem?.pairing) == -1));
    // Conditional mandatory group check
    for (let key in cmHash) {
      if (invalidPairings.includes(key)) continue;
      entryCriteria.conditionalMandatory.push(cmHash[key]);
      totalSkills += cmHash[key].numMandatorySkillsForPairing * 1;
      if (!cmHash[key].qualifies) {
        entryCriteria.canApply = false;
      };
      let conditionalMandatoryGroupQualifiesSkillCount = 0;
      cmHash[key].skillItems.forEach((skillItem) => {
        if (skillItem.maxUserExitRating >= skillItem.exitRating) conditionalMandatoryGroupQualifiesSkillCount ++;
      });
      qualifiesSkills += Math.min(Number(cmHash[key].numMandatorySkillsForPairing), conditionalMandatoryGroupQualifiesSkillCount);
    }
    // Pre-requisite specializations
    const preRequisiteSpecializations = [];
    const processPreRequite = (preReqSpec: any) => {
      const qualifies = userSpecializations.find(({ specializationId : userSpecializationId}) => preReqSpec.specializationId == userSpecializationId) ? true : false;
      if (!qualifies) entryCriteria.canApply = false;
      prerequisites.push({
        name: preReqSpec.name,
        type: 'Specialization',
        qualifies: preReqSpec.status == 'Completed',
      });
      if (preReqSpec.status == 'Completed') qualifiesPrerequisites++;
    };
    if(isPreRequisiteSpecEnabled) {
      if (!spec.preRequisiteSpecializations) {
        (spec.preRequisiteSpecializationIds || []).forEach((specializationId : string) => {
          const preReqSpec = JSON.parse(JSON.stringify(allSpecializations.find(({ specializationId : allSpecId }) => allSpecId == specializationId)));
          if (!preReqSpec) return;
          preRequisiteSpecializations.push(preReqSpec);
          processPreRequite(preReqSpec)
        });
      } else {
        spec.preRequisiteSpecializations.map((specialization: any) => {
          const preReqSpec = JSON.parse(JSON.stringify(this.ds.processAllSpecializations([specialization])[0]));
          preRequisiteSpecializations.push(preReqSpec);
          processPreRequite(preReqSpec)
        });
      }
    }
    // Setting check
    if (specializationRequestsEnabled != 'Yes') entryCriteria.canApply = false;
    if (!isQualificationRatingEnabled  && !isPreRequisiteSpecEnabled) entryCriteria.canApply = true;
    this.sortSkillItemsOnExitRating(entryCriteria);
    return {
      entryCriteria,
      preRequisiteSpecializations,
      totalSkills,
      qualifiesSkills,
      prerequisites,
      qualifiesPrerequisites,
      mandatorySkillCount: this.filterMandatorySkillWithExitRating(entryCriteria?.mandatory),
    };
  }

  getUserDataWithInitialsAndBg(data: any[] = []) {
    return data.map((d) => ({
      ...d,
      initials: (
        d.name.substr(0, 1) + (d.lastName ? d.lastName.substr(0, 1) : '')
      ).toUpperCase(),
      bg:
        '#' +
        Math.floor(200 + Math.random() * 55).toString(16) +
        Math.floor(200 + Math.random() * 55).toString(16) +
        Math.floor(200 + Math.random() * 55).toString(16),
    }));
  }

  getUniqueValuesFromCategoryWiseSettings() {
    const skillTypesSet = new Set<string>();
    const skillTagsSet = new Set<string>();
    const autoPopulateCriteriaSet = new Set<string>();

    const { categoryWiseSettings } = this.ds.client.smsSettings;
    if (!Array.isArray(categoryWiseSettings)) {
      return {
        uniqueSkillTypes: '',
        uniqueSkillTagNames: '',
        uniqueAutoPopulateSkillTaggingCriteria: '',
      };
    }

    categoryWiseSettings.forEach((category) => {
      if (Array.isArray(category.skillTypes)) {
        category.skillTypes.forEach((type) => {
          if (type.name) skillTypesSet.add(type.name.trim());
        });
      }

      if (Array.isArray(category.skillTags)) {
        category.skillTags.forEach((tag) => {
          if (tag.name) skillTagsSet.add(tag.name.trim());
        });
      }

      if (Array.isArray(category.autoPopulateSkillTaggingCriteria)) {
        category.autoPopulateSkillTaggingCriteria.forEach((criteria) => {
          if (criteria) autoPopulateCriteriaSet.add(criteria);
        });
      }
    });

    return {
      uniqueSkillTypes: Array.from(skillTypesSet).join('/'),
      uniqueSkillTagNames: Array.from(skillTagsSet).join('/'),
      uniqueAutoPopulateSkillTaggingCriteria: Array.from(autoPopulateCriteriaSet).join('/'),
    };
  }

  private filterMandatorySkillWithExitRating(specializationSkills) {
    return specializationSkills?.filter(skill => skill?.exitRating)?.length;
  }

  private sortSkillItemsOnExitRating(entryCriteria) {
    const {mandatory = [], optional = [], conditionalMandatory = [] } = entryCriteria;
    [mandatory, optional, conditionalMandatory].forEach(arr => arr.sort((a, b) => (b.exitRating || 0) - (a.exitRating || 0)));
  }
}
