export enum CONSTANTS {
  TYPE = 'TYPE',
  SOURCE = 'SOURCE',
  SUBTYPE = 'SUBTYPE',
  STATUS = 'STATUS',
}
export class LearningElementMaster {
  leId: string;
  title: string;
  status: string;
  description?: string;
  source?: string;
  type?: string;
  subType?: string;
  thumbnailUrl?: string;
  contentUrl?: string;
  originCreator?: string;
  duration?: number;
  rating?: number;
  completionTimeline?: number;
  skills: IOptionItem[];
  pk: string;
  isValidUrl?: boolean;
}

export const LEARNING_ELEMENT_MASTER_TYPE = [
  { pk: 'ASSIGNMENT', title: 'Assignment' },
  { pk: 'CERTIFICATION', title: 'Certification' },
  { pk: 'COURSE', title: 'Course' },
  { pk: 'ASSESSMENT', title: 'Assessment' },
];

export interface IlearningElementConstants {
  [CONSTANTS.TYPE]?: IOptionItem[];
  [CONSTANTS.SOURCE]?: IOptionItem[];
  [CONSTANTS.SUBTYPE]?: IOptionItem[];
  [CONSTANTS.STATUS]?: IOptionItem[];
}

export interface IFilter {
  key: string;
  title?: string;
  options?: IOptionItem[];
}

interface IOptionItem {
  pk: string;
  title: string;
  rating?: number;
}
