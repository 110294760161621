import { Component, EventEmitter, Output, AfterViewChecked, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IGoal } from 'src/app/modules/admin/admin-goal-management/components/goals';

@Component({
  selector: 'app-goals-check-in',
  templateUrl: './goals-check-in.component.html',
  styleUrls: ['./goals-check-in.component.scss']
})
export class GoalsCheckInComponent implements AfterViewChecked {

  @ViewChild('numberInput') numberInput: ElementRef;
  @Output() onCheckIn = new EventEmitter();

  public data: IGoal;
  public selectedStatus: string = 'Incomplete';
  public showInput: boolean = false;
  public percentage: number;
  public numberValue: number;
  public currencyValue: number;

  public checkInForm: FormGroup;

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(private fb: FormBuilder) {}

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnInit(): void {
    this.checkInForm = this.fb.group({
      percentage: [0, [Validators.min(0), Validators.max(100)]],
      numberValue: [0, [Validators.min(0), Validators.max(100)]],
      currencyValue: [0, [Validators.min(0)]],
      progressDescription: ['', Validators.maxLength(1000)]
    });
  }

  ngAfterViewChecked(): void {
    if (this.showInput && this.numberInput) {
      this.numberInput.nativeElement.focus();
    }
  }

  //------------------------------------------------------------------
  // Event Handlers
  //------------------------------------------------------------------

  get percentageControl() {
    return this.checkInForm.get('percentage');
  }

  get numberControl() {
    return this.checkInForm.get('numberValue');
  }

  get currencyControl() {
    return this.checkInForm.get('currencyValue');
  }

  get progressDescriptionControl() {
    return this.checkInForm.get('progressDescription');
  }

  public onCheckInClick() {
    this.onCheckIn.emit({ event: 'checkIn', data: this.data, formData: {...this.checkInForm.value, progressStatus: this.selectedStatus}});
  }

  public selectStatus(status: string) {
    this.selectedStatus = status;
  }

  public showNumberInput() {
    this.showInput = true;
  }

  public hideNumberInput() {
    this.showInput = false;
  }
}
